/* eslint-disable react/forbid-prop-types */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import DataTableDisplay from '@e2grnd/ui-core/dist/containers/DataTableDisplay'

const POFAtTimes = ({ results, problemType, whichNetwork2, whichNetwork2b, whichNetwork21, pofTable, pofRiskTable, calculateRiskFlag }) => {
  return (
    <div className="cf w-100">
      {problemType === 'PROB2'
        ? (
          <div>
            {(((whichNetwork2b === 'Sulfidation' || whichNetwork2b === 'Other Corrosion') && whichNetwork21 === 'Thinning') || ((whichNetwork2 === 'Sulfidation' || whichNetwork2 === 'Other Corrosion') && whichNetwork21 === 'unknown')) && calculateRiskFlag === true ? (
              <div>
                <p>Note, the POF and Risk reported in the table are final values, after Bayesian updating from all inspections.</p>
                <DataTableDisplay
                  id="pofRiskTable"
                  table={pofRiskTable}
                  colHeaders={{
                    USCustomary: [
                      'POF Times',
                      'POF (%)',
                      'Risk',
                    ],
                    Metric: [
                      'POF Times',
                      'POF (%)',
                      'Risk',
                    ],
                  }}
                />
              </div>
            ) :
              <div>
                <p>Note, the POF and Risk reported in the table are final values, after Bayesian updating from all inspections.</p>
                <DataTableDisplay
                  id="pofTable"
                  table={pofTable}
                  colHeaders={{
                    USCustomary: [
                      'POF Times',
                      'POF (%)',
                    ],
                    Metric: [
                      'POF Times',
                      'POF (%)',
                    ],
                  }}
                />
              </div>
            }
          </div>
        )
        : ''}
    </div>
  )
}

POFAtTimes.propTypes = {
  results: PropTypes.object,
  whichNetwork2: PropTypes.string,
  whichNetwork2b: PropTypes.string,
  whichNetwork21: PropTypes.string,
  problemType: PropTypes.string,
  calculateRiskFlag: PropTypes.bool,
  pofTable: PropTypes.object,
  pofRiskTable: PropTypes.object,
}

const mapStateToProps = state => ({
  results: state.workflow.response ? state.workflow.response.results : undefined,
  calculateRiskFlag: state.workflow.fields.calculateRiskFlag,
  problemType: state.workflow.fields.problemType,
  whichNetwork2: state.workflow.fields.whichNetwork2,
  whichNetwork2b: state.workflow.fields.whichNetwork2b,
  whichNetwork21: state.workflow.fields.whichNetwork21,
  pofTable: state.workflow.response.results.dataTables ? state.workflow.response.results.dataTables.pofTable : undefined,
  pofRiskTable: state.workflow.response.results.dataTables ? state.workflow.response.results.dataTables.pofRiskTable : undefined,
})
export default connect(mapStateToProps, null)(POFAtTimes)
