/* eslint-disable react/jsx-indent */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import SubStepper from '@e2grnd/ui-core/dist/containers/SubStepper'

function Step2({ problemType, style }) {
  return (
    <div className="cf w-100">
      {problemType === 'PROB1'
        ? (
          <div className="cf w-100">
            <SubStepper subStepIndex={0} />
          </div>
        )
        : ''}
      {problemType === 'PROB2'
        ? (
          <div className="cf w-100">
            <SubStepper subStepIndex={1} />
          </div>
        )
        : ''}
    </div>
  )
}

Step2.propTypes = {
  problemType: PropTypes.string,
  style: PropTypes.object,
}

const mapStateToProps = state => ({
  problemType: state.workflow.fields.problemType,
  style: state.ui.style,
})

export default connect(mapStateToProps)(Step2)
