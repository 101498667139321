export default [
  {
    keyword: 'VALIDKEYWORDS',
    valueType: 0,
    fieldName: undefined,
    default: undefined,
    initializer: ['STGF'],
    type: 'input',
    keywordGroupName: 'SHELL',
  },
  {
    keyword: 'PROBLEM_TYPE',
    valueType: 0,
    fieldName: 'problemType',
    default: 'BP',
    initializer: undefined,
    type: 'input',
    keywordGroupName: 'SHELL',
  },
  {
    keyword: 'VALIDKEYWORDS',
    valueType: 0,
    fieldName: undefined,
    default: undefined,
    initializer: ['PBURSTE', 'TREQE', 'BURST_PRES_GRAPH1', 'BURST_PRES_GRAPH2'],
    type: 'input',
    keywordGroupName: 'BURSTP',
  },
  {
    keyword: 'MODULE_ID',
    valueType: 0,
    fieldName: undefined,
    default: undefined,
    initializer: '1',
    type: 'input',
    keywordGroupName: 'SHELL',
  },
  {
    keyword: 'MODULE_ID',
    valueType: 0,
    fieldName: undefined,
    default: undefined,
    initializer: '2',
    type: 'input',
    keywordGroupName: 'BURSTP',
  },
  {
    keyword: 'PARENT_MODULE_ID',
    valueType: 0,
    fieldName: undefined,
    default: undefined,
    initializer: '1',
    type: 'input',
    keywordGroupName: 'BURSTP',
  },
  {
    keyword: 'PBURSTMAX',
    valueType: 2,
    fieldName: 'maxPressure',
    default: { USCustomary: 2000, Metric: 15 },
    min: 0,
    initializer: undefined,
    units: {
      USCustomary: 'psi',
      Metric: 'MPa',
    },
    tooltip: 'Required burst pressure to determine the corresponding required thickness or maximum FCA',
    type: 'input',
    keywordGroupName: 'BURSTP',
    label: 'Burst Pressure',
  },
  {
    keyword: 'P',
    valueType: 2,
    fieldName: undefined,
    default: undefined,
    initializer: 200,
    type: 'input',
    tooltip: 'Pressure used for the shell Code calculations',
    keywordGroupName: 'SHELL',
  },
  {
    keyword: 'CALC',
    valueType: 0,
    fieldName: undefined,
    default: undefined,
    initializer: 'ZTPFRM',
    type: 'input',
    keywordGroupName: 'SHELL',
  },
  {
    keyword: 'STYPE',
    valueType: 0,
    fieldName: 'shellType',
    default: 'CY',
    initializer: undefined,
    type: 'input',
    tooltip: 'Type of shell geometry to be analyzed',
    keywordGroupName: 'SHELL',
    label: 'Geometry',
  },
  {
    keyword: 'ID',
    valueType: 2,
    fieldName: 'insideDiameter',
    default: { USCustomary: 7.981, Metric: 200 },
    min: 0,
    initializer: undefined,
    units: {
      USCustomary: 'in',
      Metric: 'mm',
    },
    type: 'input',
    tooltip: 'Shell inside diameter',
    keywordGroupName: 'SHELL',
    label: 'Inside Diameter',
  },
  {
    keyword: 'ST',
    valueType: 2,
    fieldName: 'thickness',
    default: { USCustomary: 0.322, Metric: 8 },
    min: 1e-3,
    initializer: { USCustomary: 0.322, Metric: 8 },
    units: {
      USCustomary: 'in',
      Metric: 'mm',
    },
    type: 'input',
    tooltip: 'Measured or nominal thickness of the shell',
    keywordGroupName: 'SHELL',
    label: 'Nominal Thickness',
  },
  {
    keyword: 'SPEC',
    valueType: 0,
    fieldName: 'materialSpecification',
    default: [],
    initializer: undefined,
    type: 'input',
    keywordGroupName: 'SHELL',
    label: 'Material Specification',
  },
  {
    keyword: 'STEM',
    valueType: 2,
    fieldName: 'temperature',
    default: { USCustomary: 325, Metric: 160 },
    initializer: undefined,
    units: {
      USCustomary: 'F',
      Metric: 'C',
    },
    type: 'input',
    tooltip: 'Temperature used for the shell Code calculations',
    keywordGroupName: 'SHELL',
    label: 'Temperature',
  },
  {
    keyword: 'PBURSTE',
    valueType: 2,
    fieldName: 'calculatedBurstPressure',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'psi',
      Metric: 'MPa',
    },
    type: 'output',
    tooltip: 'Calculated burst pressure using the exact method',
    keywordGroupName: 'BURSTP',
    label: 'Burst Pressure',
    dashboardSortOrder: 'asc',
  },
  {
    keyword: 'TREQE',
    valueType: 2,
    fieldName: 'exactRequiredThickness',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'in',
      Metric: 'mm',
    },
    type: 'output',
    tooltip: 'Required thickness of the component. Equal to the nominal thickness adjusted for undertolerance and uniform metal loss where applicable.',
    keywordGroupName: 'BURSTP',
    label: 'Burst Thickness',
  },
]
