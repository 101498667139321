export default [
  // **************************************************************************************
  // New Inputs
  // **************************************************************************************
  {
    keyword: 'componentAgeVsInstallDateBool',
    valueType: 1,
    fieldName: 'componentAgeVsInstallDateBool',
    default: false,
    initializer: undefined,
    type: 'input',
    label: 'Component Age vs Install Date Bool',
    tooltip: 'Component Age vs Install Date Bool',
    transform: (value) => {
      if(value === true || value === 'true') {
        return 1
      }
      return 0
    },
    inverseTransform: (value) => {
      if(value === '1' || value === 1) {
        return true
      }
      return false
    },
  },
  {
    keyword: 'calculateRiskFlag',
    valueType: 1,
    fieldName: 'calculateRiskFlag',
    default: false,
    initializer: undefined,
    type: 'input',
    label: 'Calculate Risk Bool',
    tooltip: 'Calculate Risk Bool',
    transform: (value) => {
      if(value === true || value === 'true') {
        return 1
      }
      return 0
    },
    inverseTransform: (value) => {
      if(value === '1' || value === 1) {
        return true
      }
      return false
    },
  },
  {
    keyword: 'timeSinceInstall',
    valueType: 0,
    fieldName: 'timeSinceInstall',
    default: '01/01/1990',
    initializer: undefined,
    type: 'input',
    label: 'Component Install/Last Replacement Date',
  },
  // **************************************************************************************
  // **************************************************************************************
  //                                      General Inputs
  // **************************************************************************************
  // **************************************************************************************
  {
    keyword: 'LAST_SUCCESSFUL_JOB_ID',
    valueType: 0,
    fieldName: 'previousJobId',
    default: '',
    initializer: undefined,
    type: 'input',
    label: 'Previous Job ID',
  },
  {
    keyword: 'whichVarianceCR',
    valueType: 0,
    fieldName: 'whichVarianceCR',
    default: 'Confidence',
    initializer: undefined,
    type: 'input',
    label: 'Which Variance Type',
  },
  {
    keyword: 'otherCorrosionType',
    valueType: 0,
    fieldName: 'otherCorrosionType',
    default: 'probabilistic',
    initializer: undefined,
    type: 'input',
    label: 'Which Corrosion Rate Input Type',
  },
  {
    keyword: 'otherCorrosionRateProb',
    fieldName: 'otherCorrosionRateProb',
    valueType: 0,
    default: JSON.stringify({
      name: 'corrosionRate',
      kind: 'nature',
      type: 'continuous',
      parents: null,
      states: {
        levels: [0, 0.1, 0.5, 1, 2, 3, 5, 7, 10, 15, 20, 25, 30, 40, 50, 60, 80, 100, 150, 200, 300, 400, 500, 600, 800, 1000],
      },
      CPT: {
        neticaEquation: 'p(corrosionRate|)=NormalDist(temperature, 10, 3)',
        sampleSize: 10,
      },
      distributionType: 'normal',
      median: 10,
      varianceType: 'standardDeviation',
      standardDeviation: 3,
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'Corrosion Rate',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'otherRateHistory',
    default: [[], []],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: 'otherRateSensor',
    valueType: 0,
    fieldName: 'otherRateSensor',
    default: undefined,
    initializer: undefined,
    type: 'input',
    label: 'Corrosion Rate Time Series Sensor',
  },
  {
    keyword: 'ndeSensor',
    valueType: 0,
    fieldName: 'ndeSensor',
    default: undefined,
    initializer: undefined,
    type: 'input',
    label: 'NDE Time Series Sensor',
  },
  {
    keyword: 'tempSensor',
    valueType: 0,
    fieldName: 'tempSensor',
    default: undefined,
    initializer: undefined,
    type: 'input',
    label: 'Temperature Time Series Sensor',
  },
  {
    keyword: 'sulfurConcentrationSensor',
    valueType: 0,
    fieldName: 'sulfurConcentrationSensor',
    default: undefined,
    initializer: undefined,
    type: 'input',
    label: 'Sulfur Concentration Time Series Sensor',
  },
  {
    keyword: 'expertCorrosionRateCV',
    valueType: 2,
    fieldName: 'expertCorrosionRateCV',
    min: 0,
    max: 100,
    default: 0.1,
    initializer: undefined,
    type: 'input',
    label: 'Corrosion Rate CV',
  },
  {
    keyword: 'problemType',
    valueType: 0,
    fieldName: 'problemType',
    default: 'PROB1',
    initializer: undefined,
    type: 'input',
    label: 'Problem Type',
  },
  {
    keyword: 'measurementErrorInputType',
    valueType: 0,
    fieldName: 'measurementErrorInputType',
    default: 'thickness',
    initializer: undefined,
    type: 'input',
    label: 'Measurement Error Input Type',
  },
  {
    keyword: 'measurementErrorVarianceType',
    valueType: 0,
    fieldName: 'measurementErrorVarianceType',
    default: 'sd',
    initializer: undefined,
    type: 'input',
    label: 'Measurement Error Variance Type',
  },
  {
    keyword: 'whichNetwork1',
    valueType: 0,
    fieldName: 'whichNetwork1',
    default: 'Cracking',
    initializer: undefined,
    type: 'input',
    label: 'Chosen Network',
  },
  {
    keyword: 'temperatureSulidationType',
    valueType: 0,
    fieldName: 'temperatureSulidationType',
    default: 'constant',
    initializer: undefined,
    type: 'input',
    label: 'Temperature Input Type',
  },
  {
    keyword: 'sulfurConcentrationType',
    valueType: 0,
    fieldName: 'sulfurConcentrationType',
    default: 'constant',
    initializer: undefined,
    type: 'input',
    label: 'Sulfur Concentration Input Type',
  },
  {
    keyword: 'temperatureSulidationDMProb',
    fieldName: 'temperatureSulidationDMProb',
    valueType: 0,
    default: JSON.stringify({
      name: 'temperature',
      kind: 'nature',
      type: 'continuous',
      parents: null,
      states: {
        levels: [400, 425, 450, 475, 500, 525, 550, 575, 600, 625, 650, 675, 700, 725, 750, 775, 800],
      },
      CPT: {
        neticaEquation: 'p(temperature|)=NormalDist(temperature, 600, 50)',
        sampleSize: 10,
      },
      distributionType: 'normal',
      median: 600,
      varianceType: 'standardDeviation',
      standardDeviation: 50,
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'Temperature',
  },
  {
    keyword: 'sulfurConcentration1Prob',
    fieldName: 'sulfurConcentration1Prob',
    valueType: 0,
    default: JSON.stringify({
      name: 'concentration',
      kind: 'nature',
      type: 'continuous',
      parents: null,
      states: {
        levels: [0, 0.001, 0.005, 0.01, 0.05, 0.1, 0.5, 1, 2, 3, 5, 7, 10],
      },
      CPT: {
        neticaEquation: 'p(concentration|)=NormalDist(concentration, 1, 0.3)',
        sampleSize: 100,
      },
      distributionType: 'normal',
      median: 1,
      varianceType: 'standardDeviation',
      standardDeviation: 0.3,
    }, null, 4),
    initializer: undefined,
    type: 'input',
    label: 'Sulfur Concentration',
  },
  {
    keyword: 'whichNetwork2',
    valueType: 0,
    fieldName: 'whichNetwork2',
    default: '885 Embrittlement',
    initializer: undefined,
    type: 'input',
    label: 'Chosen Network',
  },
  {
    keyword: 'whichNetwork2a',
    valueType: 0,
    fieldName: 'whichNetwork2a',
    default: '885 Embrittlement',
    initializer: undefined,
    type: 'input',
    label: 'Chosen Network',
  },
  {
    keyword: 'whichNetwork2b',
    valueType: 0,
    fieldName: 'whichNetwork2b',
    default: 'Acid Sour Water Corrosion',
    initializer: undefined,
    type: 'input',
    label: 'Chosen Network',
  },
  {
    keyword: 'whichNetwork21',
    valueType: 0,
    fieldName: 'whichNetwork21',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Chosen Network',
  },
  // **************************************************************************************
  // **************************************************************************************
  //                                      Problem Type 1 Inputs
  // **************************************************************************************
  // **************************************************************************************
  // *****************************************************
  // Cracking
  // *****************************************************
  {
    keyword: 'processUnit',
    valueType: 0,
    fieldName: 'processUnit',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Process Unit',
  },
  {
    keyword: 'aminePresent',
    valueType: 0,
    fieldName: 'aminePresent',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Amine Present',
  },
  {
    keyword: 'previousJobFlag',
    valueType: 0,
    fieldName: 'previousJobFlag',
    default: 'no',
    initializer: undefined,
    type: 'input',
    label: 'Previous Job?',
  },
  {
    keyword: 'inspectionsFlag',
    valueType: 0,
    fieldName: 'inspectionsFlag',
    default: 'no',
    initializer: undefined,
    type: 'input',
    label: 'Inspection?',
  },
  {
    keyword: 'maintenanceFlag',
    valueType: 0,
    fieldName: 'maintenanceFlag',
    default: 'no',
    initializer: undefined,
    type: 'input',
    label: 'Maintenance?',
  },
  {
    keyword: 'p1HfPresent',
    valueType: 0,
    fieldName: 'p1HfPresent',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'HF Present',
  },
  {
    keyword: 'chloridesPresent',
    valueType: 0,
    fieldName: 'chloridesPresent',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Chlorides Present',
  },
  {
    keyword: 'sulfurPresent',
    valueType: 0,
    fieldName: 'sulfurPresent',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Sulfur Present',
  },
  {
    keyword: 'naOhPresent',
    valueType: 0,
    fieldName: 'naOhPresent',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'NaOH Present',
  },
  {
    keyword: 'h2SPresent',
    valueType: 0,
    fieldName: 'h2SPresent',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'H2S Present',
  },
  {
    keyword: 'lowOrHighTemp',
    valueType: 0,
    fieldName: 'lowOrHighTemp',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Low or High Temp',
  },
  {
    keyword: 'pHKnown',
    valueType: 0,
    fieldName: 'pHKnown',
    default: 'Yes',
    initializer: undefined,
    type: 'input',
    label: 'Operating pH Known',
  },
  {
    keyword: 'operatingTemperatureKnown',
    valueType: 0,
    fieldName: 'operatingTemperatureKnown',
    default: 'Yes',
    initializer: undefined,
    type: 'input',
    label: 'Operating Temperature Known',
  },
  {
    keyword: 'p1Temperature',
    valueType: 2,
    fieldName: 'p1Temperature',
    min: { USCustomary: -4.0, Metric: -20.0 },
    max: { USCustomary: 1112.0, Metric: 600.0 },
    default: { USCustomary: 200.0, Metric: 94.0 },
    initializer: undefined,
    units: {
      USCustomary: 'F',
      Metric: 'C',
    },
    type: 'input',
    label: 'Operating Temperature',
  },
  {
    keyword: 'p1Materiala',
    valueType: 0,
    fieldName: 'p1Materiala',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Material of Construction',
  },
  {
    keyword: 'p1BrinnelHardness',
    valueType: 0,
    fieldName: 'p1BrinnelHardness',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Brinnel Hardness',
  },
  {
    keyword: 'chlorideSource',
    valueType: 0,
    fieldName: 'chlorideSource',
    default: 'Internal',
    initializer: undefined,
    type: 'input',
    label: 'Damage Source',
  },
  {
    keyword: 'freeWaterPresent',
    valueType: 0,
    fieldName: 'freeWaterPresent',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Free Water Present',
  },
  {
    keyword: 'expertDMConfidence',
    valueType: 0,
    fieldName: 'expertDMConfidence',
    default: 'Low',
    initializer: undefined,
    type: 'input',
    label: 'Expert DM Confidence',
  },
  {
    keyword: 'expertAmine',
    valueType: 0,
    fieldName: 'expertAmine',
    default: 'No',
    initializer: undefined,
    type: 'input',
    label: 'Expert Amine',
  },
  {
    keyword: 'expertHF',
    valueType: 0,
    fieldName: 'expertHF',
    default: 'No',
    initializer: undefined,
    type: 'input',
    label: 'Expert HF',
  },
  {
    keyword: 'p1WeightMethod',
    valueType: 0,
    fieldName: 'p1WeightMethod',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Weight Method',
  },
  {
    keyword: 'contaminants',
    valueType: 0,
    fieldName: 'contaminants',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Contaminants',
  },
  {
    keyword: 'fluidType',
    valueType: 0,
    fieldName: 'fluidType',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Fluid Type',
  },
  {
    keyword: 'expertChlorideSCC2',
    valueType: 0,
    fieldName: 'expertChlorideSCC2',
    default: 'No',
    initializer: undefined,
    type: 'input',
    label: 'Expert Chloride External',
  },
  {
    keyword: 'expertPolyAcid',
    valueType: 0,
    fieldName: 'expertPolyAcid',
    default: 'No',
    initializer: undefined,
    type: 'input',
    label: 'Expert Poly Acid',
  },
  {
    keyword: 'expertCaustic',
    valueType: 0,
    fieldName: 'expertCaustic',
    default: 'No',
    initializer: undefined,
    type: 'input',
    label: 'Expert Caustic',
  },
  {
    keyword: 'expertSulfidation',
    valueType: 0,
    fieldName: 'expertSulfidation',
    default: 'No',
    initializer: undefined,
    type: 'input',
    label: 'Expert Sulfidation',
  },
  {
    keyword: 'expertSulfide',
    valueType: 0,
    fieldName: 'expertSulfide',
    default: 'No',
    initializer: undefined,
    type: 'input',
    label: 'Expert Sulfide',
  },
  {
    keyword: 'expertHIC',
    valueType: 0,
    fieldName: 'expertHIC',
    default: 'No',
    initializer: undefined,
    type: 'input',
    label: 'Expert HIC',
  },
  {
    keyword: 'expertHICHF',
    valueType: 0,
    fieldName: 'expertHICHF',
    default: 'No',
    initializer: undefined,
    type: 'input',
    label: 'Expert HIC in HF',
  },
  {
    keyword: 'p1PH',
    valueType: 2,
    fieldName: 'p1PH',
    max: 14,
    default: 7,
    initializer: undefined,
    type: 'input',
    label: 'pH',
  },
  {
    keyword: 'p1H2PartialPressure',
    valueType: 0,
    fieldName: 'p1H2PartialPressure',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'H2 Partial Pressure',
  },
  {
    keyword: 'stainlessType',
    valueType: 0,
    fieldName: 'stainlessType',
    default: 'Austenitic',
    initializer: undefined,
    type: 'input',
    label: 'Stainless Type',
  },
  {
    keyword: 'belowMAT',
    valueType: 0,
    fieldName: 'belowMAT',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Material Operates Below MAT',
  },
  {
    keyword: 'expertChlorideSCC',
    valueType: 0,
    fieldName: 'expertChlorideSCC',
    default: 'No',
    initializer: undefined,
    type: 'input',
    label: 'Expert Chloride Internal',
  },
  {
    keyword: 'expertAlkaline',
    valueType: 0,
    fieldName: 'expertAlkaline',
    default: 'No',
    initializer: undefined,
    type: 'input',
    label: 'Expert Alkaline',
  },
  {
    keyword: 'expertHTHA',
    valueType: 0,
    fieldName: 'expertHTHA',
    default: 'No',
    initializer: undefined,
    type: 'input',
    label: 'Expert HTHA',
  },
  {
    keyword: 'expertLowAllow',
    valueType: 0,
    fieldName: 'expertLowAllow',
    default: 'No',
    initializer: undefined,
    type: 'input',
    label: 'Expert Low Alloy',
  },
  {
    keyword: 'expert885',
    valueType: 0,
    fieldName: 'expert885',
    default: 'No',
    initializer: undefined,
    type: 'input',
    label: 'Expert 885',
  },
  {
    keyword: 'expertSigmaPhase',
    valueType: 0,
    fieldName: 'expertSigmaPhase',
    default: 'No',
    initializer: undefined,
    type: 'input',
    label: 'Expert Sigma Phase',
  },
  {
    keyword: 'expertBrittle',
    valueType: 0,
    fieldName: 'expertBrittle',
    default: 'No',
    initializer: undefined,
    type: 'input',
    label: 'Expert Brittle',
  },
  // *****************************************************
  // Thinning
  // *****************************************************
  {
    keyword: 'p1Materialb',
    valueType: 0,
    fieldName: 'p1Materialb',
    default: 'Carbon Steel',
    initializer: undefined,
    type: 'input',
    label: 'Material of Construction',
  },
  {
    keyword: 'equipmentType',
    valueType: 0,
    fieldName: 'equipmentType',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Equipment Type',
  },
  {
    keyword: 'soilContact',
    valueType: 0,
    fieldName: 'soilContact',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Soil Contact',
  },
  {
    keyword: 'waterPresent',
    valueType: 0,
    fieldName: 'waterPresent',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Water Present in Stream',
  },
  {
    keyword: 'co2Present',
    valueType: 0,
    fieldName: 'co2Present',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'CO2 Present in Stream',
  },
  {
    keyword: 'coolingWater',
    valueType: 0,
    fieldName: 'coolingWater',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Cooling Water',
  },
  {
    keyword: 'p1O2Present',
    valueType: 0,
    fieldName: 'p1O2Present',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'O2 Present',
  },
  {
    keyword: 'highTempOxidation',
    valueType: 0,
    fieldName: 'highTempOxidation',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'High Temp Oxidation',
  },
  {
    keyword: 'ammoniumBisulfide',
    valueType: 0,
    fieldName: 'ammoniumBisulfide',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Ammonium Bisulfide Present',
  },
  {
    keyword: 'sulfuricAcidPresent',
    valueType: 0,
    fieldName: 'sulfuricAcidPresent',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Sulfuric Acid Present',
  },
  {
    keyword: 'crudeOil',
    valueType: 0,
    fieldName: 'crudeOil',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Crude Oil Present',
  },
  {
    keyword: 'hclPresent',
    valueType: 0,
    fieldName: 'hclPresent',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'HCl Present',
  },
  {
    keyword: 'h2Present',
    valueType: 0,
    fieldName: 'h2Present',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'H2 Present',
  },
  {
    keyword: 'p1Velocity',
    valueType: 0,
    fieldName: 'p1Velocity',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Velocity',
  },
  {
    keyword: 'expertTankBottom',
    valueType: 0,
    fieldName: 'expertTankBottom',
    default: 'No',
    initializer: undefined,
    type: 'input',
    label: 'Expert Tank Bottom',
  },
  {
    keyword: 'expertSoilSide',
    valueType: 0,
    fieldName: 'expertSoilSide',
    default: 'No',
    initializer: undefined,
    type: 'input',
    label: 'Expert Soil Side',
  },
  {
    keyword: 'expertThinningTypeConfidence',
    valueType: 0,
    fieldName: 'expertThinningTypeConfidence',
    default: 'Low',
    initializer: undefined,
    type: 'input',
    label: 'Expert Thinning Type Confidence',
  },
  {
    keyword: 'expertThinningType',
    valueType: 0,
    fieldName: 'expertThinningType',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Expert Thinning Type',
  },
  {
    keyword: 'expertCO2',
    valueType: 0,
    fieldName: 'expertCO2',
    default: 'No',
    initializer: undefined,
    type: 'input',
    label: 'Expert CO2',
  },
  {
    keyword: 'expertAcidSourWater',
    valueType: 0,
    fieldName: 'expertAcidSourWater',
    default: 'No',
    initializer: undefined,
    type: 'input',
    label: 'Expert Acid Sour Water',
  },
  {
    keyword: 'expertCoolingWater',
    valueType: 0,
    fieldName: 'expertCoolingWater',
    default: 'No',
    initializer: undefined,
    type: 'input',
    label: 'Expert Cooling Water',
  },
  {
    keyword: 'expertOxidation',
    valueType: 0,
    fieldName: 'expertOxidation',
    default: 'No',
    initializer: undefined,
    type: 'input',
    label: 'Expert Oxidation',
  },
  {
    keyword: 'expertSulfuric',
    valueType: 0,
    fieldName: 'expertSulfuric',
    default: 'No',
    initializer: undefined,
    type: 'input',
    label: 'Expert Sulfuric',
  },
  {
    keyword: 'expertNapthenic',
    valueType: 0,
    fieldName: 'expertNapthenic',
    default: 'No',
    initializer: undefined,
    type: 'input',
    label: 'Expert Napthenic',
  },
  {
    keyword: 'expertHcl',
    valueType: 0,
    fieldName: 'expertHcl',
    default: 'No',
    initializer: undefined,
    type: 'input',
    label: 'Expert HCL',
  },
  {
    keyword: 'expertH2S',
    valueType: 0,
    fieldName: 'expertH2S',
    default: 'No',
    initializer: undefined,
    type: 'input',
    label: 'Expert High Temp H2S',
  },
  {
    keyword: 'whichExpertModelMethod',
    valueType: 0,
    fieldName: 'whichExpertModelMethod',
    default: 'Expert Prior Caused by Prediction',
    initializer: undefined,
    type: 'input',
    label: 'Expert Prior Model Option',
  },
  {
    keyword: 'confidenceInMethodsSusceptibility',
    valueType: 0,
    fieldName: 'confidenceInMethodsSusceptibility',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Susceptibility Method Weight',
  },
  {
    keyword: 'confidenceInMethodsCracks',
    valueType: 0,
    fieldName: 'confidenceInMethodsCracks',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Cracks Method Weight',
  },
  {
    keyword: 'confidenceInMethodsDamageFactor',
    valueType: 0,
    fieldName: 'confidenceInMethodsDamageFactor',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Damage Factor Method Weight',
  },
  {
    keyword: 'confidenceInMethodsCR',
    valueType: 0,
    fieldName: 'confidenceInMethodsCR',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Corrosion Rate Method Weight',
  },
  {
    keyword: 'expertDMConfidence1',
    valueType: 0,
    fieldName: 'expertDMConfidence1',
    default: 'Low',
    initializer: undefined,
    type: 'input',
    label: 'Expert DM Confidence',
  },
  {
    keyword: 'expertPriorLikelyDM',
    valueType: 1,
    fieldName: 'expertPriorLikelyDM',
    default: true,
    initializer: undefined,
    type: 'input',
    label: 'Expert Prior for the Likely Damage Mechanisms?',
    tooltip: 'Expert Prior for the Likely Damage Mechanisms?',
    transform: (value) => {
      if(value === true || value === 'true') {
        return 1
      }
      return 0
    },
    inverseTransform: (value) => {
      if(value === '1' || value === 1) {
        return true
      }
      return false
    },
  },
  {
    keyword: 'tableSoftEvid',
    valueType: 1,
    fieldName: 'tableSoftEvid',
    default: false,
    initializer: undefined,
    type: 'input',
    label: 'Select Single Expert or Probability Table',
    tooltip: 'Select Single Expert or Probability Table',
    transform: (value) => {
      if(value === true || value === 'true') {
        return 1
      }
      return 0
    },
    inverseTransform: (value) => {
      if(value === '1' || value === 1) {
        return true
      }
      return false
    },
  },
  // {
  //   keyword: 'expertPriorLikelyDM',
  //   valueType: 0,
  //   fieldName: 'expertPriorLikelyDM',
  //   default: 'No',
  //   initializer: undefined,
  //   type: 'input',
  //   label: 'Expert Prior for the Likely Damage Mechanisms?',
  // },
  {
    keyword: 'mostLikelyExpertDMCracking',
    valueType: 0,
    fieldName: 'mostLikelyExpertDMCracking',
    default: 'expert885',
    initializer: undefined,
    type: 'input',
    label: 'Most Likely Expert Damage Mechanisms for Crackings?',
  },
  {
    keyword: 'mostLikelyExpertDMThinning',
    valueType: 0,
    fieldName: 'mostLikelyExpertDMThinning',
    default: 'expertAcidSourWater',
    initializer: undefined,
    type: 'input',
    label: 'Most Likely Expert Damage Mechanisms for Thinning?',
  },
  {
    keyword: 'weightTheThreeLikelihoodMcVaySusceptibility',
    valueType: 2,
    fieldName: 'weightTheThreeLikelihoodMcVaySusceptibility',
    min: 0,
    max: 100,
    default: 33,
    units: {
      USCustomary: '%',
      Metric: '%',
    },
    initializer: undefined,
    type: 'input',
    label: 'McVay Weight Likelihood Value Susceptibility',
  },
  {
    keyword: 'weightTheThreeLikelihoodExpertSusceptibility',
    valueType: 2,
    fieldName: 'weightTheThreeLikelihoodExpertSusceptibility',
    min: 0,
    max: 100,
    default: 33,
    units: {
      USCustomary: '%',
      Metric: '%',
    },
    initializer: undefined,
    type: 'input',
    label: 'Expert Weight Likelihood Value Susceptibility',
  },
  {
    keyword: 'weightTheThreeLikelihoodAPI581Susceptibility',
    valueType: 2,
    fieldName: 'weightTheThreeLikelihoodAPI581Susceptibility',
    min: 0,
    max: 100,
    default: 34,
    units: {
      USCustomary: '%',
      Metric: '%',
    },
    initializer: undefined,
    type: 'input',
    label: 'API 581 Weight Likelihood Value Susceptibility',
  },
  {
    keyword: 'weightTheThreeLikelihoodDobisSusceptibility',
    valueType: 2,
    fieldName: 'weightTheThreeLikelihoodDobisSusceptibility',
    min: 0,
    max: 100,
    default: 33,
    units: {
      USCustomary: '%',
      Metric: '%',
    },
    initializer: undefined,
    type: 'input',
    label: 'Dobis Weight Likelihood Value Susceptibility',
  },
  {
    keyword: 'weightTheTwoLikelihoodPredVsExpertSusceptibility',
    valueType: 2,
    fieldName: 'weightTheTwoLikelihoodPredVsExpertSusceptibility',
    min: 0,
    max: 100,
    default: 10,
    units: {
      USCustomary: '%',
      Metric: '%',
    },
    initializer: undefined,
    type: 'input',
    label: 'Susceptibility Prediction Weight vs Expert Value',
  },
  {
    keyword: 'weightTheTwoLikelihoodPredVsExpertDamageFactor',
    valueType: 2,
    fieldName: 'weightTheTwoLikelihoodPredVsExpertDamageFactor',
    min: 0,
    max: 100,
    default: 10,
    units: {
      USCustomary: '%',
      Metric: '%',
    },
    initializer: undefined,
    type: 'input',
    label: 'Damage Factor Prediction Weight vs Expert Value',
  },
  {
    keyword: 'weightTheTwoLikelihoodPredVsExpertCracks',
    valueType: 2,
    fieldName: 'weightTheTwoLikelihoodPredVsExpertCracks',
    min: 0,
    max: 100,
    default: 10,
    units: {
      USCustomary: '%',
      Metric: '%',
    },
    initializer: undefined,
    type: 'input',
    label: 'Cracks Prediction Weight vs Expert Value',
  },
  {
    keyword: 'weightTheTwoLikelihoodAPIVsDobis',
    valueType: 2,
    fieldName: 'weightTheTwoLikelihoodAPIVsDobis',
    min: 0,
    max: 100,
    default: 10,
    units: {
      USCustomary: '%',
      Metric: '%',
    },
    initializer: undefined,
    type: 'input',
    label: 'Weight The Two Likelihood Value',
  },
  {
    keyword: 'weightTheTwoLikelihoodPredVsExpert',
    valueType: 2,
    fieldName: 'weightTheTwoLikelihoodPredVsExpert',
    min: 0,
    max: 100,
    default: 10,
    units: {
      USCustomary: '%',
      Metric: '%',
    },
    initializer: undefined,
    type: 'input',
    label: 'Weight The Two Likelihood Value',
  },
  {
    keyword: 'weightTheTwoLikelihood',
    valueType: 2,
    fieldName: 'weightTheTwoLikelihood',
    min: 0,
    max: 100,
    default: 10,
    units: {
      USCustomary: '%',
      Metric: '%',
    },
    initializer: undefined,
    type: 'input',
    label: 'Weight The Two Likelihood Value',
  },
  {
    keyword: 'weightTheThreeLikelihoodAPI581CR',
    valueType: 2,
    fieldName: 'weightTheThreeLikelihoodAPI581CR',
    min: 0,
    max: 100,
    default: 34,
    units: {
      USCustomary: '%',
      Metric: '%',
    },
    initializer: undefined,
    type: 'input',
    label: 'API 581 Weight Likelihood Value CR',
  },
  {
    keyword: 'weightTheThreeLikelihoodExpertCR',
    valueType: 2,
    fieldName: 'weightTheThreeLikelihoodExpertCR',
    min: 0,
    max: 100,
    default: 33,
    units: {
      USCustomary: '%',
      Metric: '%',
    },
    initializer: undefined,
    type: 'input',
    label: 'Expert Weight Likelihood Value CR',
  },
  {
    keyword: 'weightTheThreeLikelihoodDobisCR',
    valueType: 2,
    fieldName: 'weightTheThreeLikelihoodDobisCR',
    min: 0,
    max: 100,
    default: 33,
    units: {
      USCustomary: '%',
      Metric: '%',
    },
    initializer: undefined,
    type: 'input',
    label: 'Dobis Weight Likelihood Value CR',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'inspectionHistory',
    default: [[], [], [], []],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'maintenanceHistory',
    default: [[], []],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'utHistory',
    default: [[], []],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'tempHistory',
    default: [[], []],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'sulfurConcentrationHistory',
    default: [[], []],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'tableSoftEvidCrackingTable',
    default: [['0', 0], ['0', 0], ['0', 0], ['0', 0], ['0', 0], ['0', 0], ['0', 0], ['0', 0], ['0', 0], ['0', 0], ['0', 0], ['0', 0], ['0', 0], ['0', 0]],
    // default: [],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 2,
    fieldName: 'tableSoftEvidThinningTable',
    default: [['0', 0], ['0', 0], ['0', 0], ['0', 0], ['0', 0], ['0', 0], ['0', 0], ['0', 0], ['0', 0], ['0', 0], ['0', 0], ['0', 0], ['0', 0], ['0', 0]],
    initializer: undefined,
    type: 'input',
  },
  // **************************************************************************************
  // **************************************************************************************
  //                                      Problem Type 2 Inputs
  // **************************************************************************************
  // **************************************************************************************
  {
    keyword: 'whichSubNetwork2a',
    valueType: 0,
    fieldName: 'whichSubNetwork2a',
    default: '0',
    initializer: undefined,
    type: 'input',
    label: 'Chosen Specific Network',
  },
  {
    keyword: 'whichSubNetwork2b',
    valueType: 0,
    fieldName: 'whichSubNetwork2b',
    default: '0',
    initializer: undefined,
    type: 'input',
    label: 'Chosen Specific Network',
  },
  {
    keyword: 'whichSubNetwork2c',
    valueType: 0,
    fieldName: 'whichSubNetwork2c',
    default: '0',
    initializer: undefined,
    type: 'input',
    label: 'Chosen Specific Network',
  },
  {
    keyword: 'whichSubNetwork2d',
    valueType: 0,
    fieldName: 'whichSubNetwork2d',
    default: '0',
    initializer: undefined,
    type: 'input',
    label: 'Chosen Specific Network',
  },
  {
    keyword: 'whichSubNetwork2e',
    valueType: 0,
    fieldName: 'whichSubNetwork2e',
    default: '0',
    initializer: undefined,
    type: 'input',
    label: 'Chosen Specific Network',
  },
  {
    keyword: 'whichSubNetwork2f',
    valueType: 0,
    fieldName: 'whichSubNetwork2f',
    default: '0',
    initializer: undefined,
    type: 'input',
    label: 'Chosen Specific Network',
  },
  {
    keyword: 'whichSubNetwork2g',
    valueType: 0,
    fieldName: 'whichSubNetwork2g',
    default: '0',
    initializer: undefined,
    type: 'input',
    label: 'Chosen Specific Network',
  },
  {
    keyword: 'whichModelVarianceCR',
    valueType: 0,
    fieldName: 'whichModelVarianceCR',
    default: 'Confidence',
    initializer: undefined,
    type: 'input',
    label: 'Model Corrosion Rate Variance Type',
  },
  {
    keyword: 'modelConfidenceCR',
    valueType: 0,
    fieldName: 'modelConfidenceCR',
    default: 'Low',
    initializer: undefined,
    type: 'input',
    label: 'Model Corrosion Rate Confidence',
  },
  {
    keyword: 'modelSusceptibility',
    valueType: 0,
    fieldName: 'modelSusceptibility',
    default: 'Unknown',
    initializer: undefined,
    type: 'input',
    label: 'Model Susceptibility',
  },
  {
    keyword: 'modelCR',
    valueType: 2,
    fieldName: 'modelCR',
    min: 0,
    default: 10.0,
    initializer: undefined,
    units: {
      USCustomary: 'mil/yr',
      Metric: 'mm/yr',
    },
    type: 'input',
    label: 'Model Corrosion Rate',
  },
  {
    keyword: 'modelCvCR',
    valueType: 2,
    fieldName: 'modelCvCR',
    min: 0,
    max: 10,
    default: 0.2,
    initializer: undefined,
    type: 'input',
    label: 'Model Corrosion Rate CV',
  },
  {
    keyword: 'consequence',
    valueType: 2,
    fieldName: 'consequence',
    min: 0,
    default: 1,
    initializer: undefined,
    type: 'input',
    label: 'Risk Consequence',
  },
  {
    keyword: 'modelSdCR',
    valueType: 2,
    fieldName: 'modelSdCR',
    min: 0,
    default: 2.0,
    initializer: undefined,
    units: {
      USCustomary: 'mil/yr',
      Metric: 'mm/yr',
    },
    type: 'input',
    label: 'Model Corrosion Rate',
  },
  {
    keyword: 'whichSubNetwork2h',
    valueType: 0,
    fieldName: 'whichSubNetwork2h',
    default: '0',
    initializer: undefined,
    type: 'input',
    label: 'Chosen Specific Network',
  },
  {
    keyword: 'undertolerance',
    valueType: 2,
    fieldName: 'undertolerance',
    min: 0.00001,
    default: 12.5,
    initializer: undefined,
    units: {
      USCustomary: '%',
      Metric: '%',
    },
    type: 'input',
    label: 'Component Undertolerance',
  },
  {
    keyword: 'innerDiameter',
    valueType: 2,
    fieldName: 'innerDiameter',
    min: { USCustomary: 0.0, Metric: 0.0 },
    default: { USCustomary: 12.0, Metric: 304.8 },
    initializer: undefined,
    units: {
      USCustomary: 'in',
      Metric: 'mm',
    },
    type: 'input',
    label: 'Inner Diameter',
  },
  {
    keyword: 'internalPressure',
    valueType: 2,
    fieldName: 'internalPressure',
    min: { USCustomary: 0.0, Metric: 0.0 },
    default: { USCustomary: 100.0, Metric: 0.7 },
    initializer: undefined,
    units: {
      USCustomary: 'psi',
      Metric: 'MPa',
    },
    type: 'input',
    label: 'Internal Pressure',
  },
  {
    keyword: 'flowStress',
    valueType: 2,
    fieldName: 'flowStress',
    min: { USCustomary: 0.0, Metric: 0.0 },
    default: { USCustomary: 40.0, Metric: 275.0 },
    initializer: undefined,
    units: {
      USCustomary: 'ksi',
      Metric: 'MPa',
    },
    type: 'input',
    label: 'Flow Stress',
  },
  {
    keyword: 'failureThicknessMethod',
    valueType: 0,
    fieldName: 'failureThicknessMethod',
    default: 'specify',
    initializer: undefined,
    type: 'input',
    label: 'Failure Thickness Method',
  },
  {
    keyword: 'failureThickness',
    valueType: 2,
    fieldName: 'failureThickness',
    min: { USCustomary: 0.0, Metric: 0.0 },
    default: { USCustomary: 0.0, Metric: 0.0 },
    initializer: undefined,
    units: {
      USCustomary: 'in',
      Metric: 'mm',
    },
    type: 'input',
    label: 'Component Failure Thickness',
  },
  {
    keyword: 'failureThicknessConfidence',
    valueType: 0,
    fieldName: 'failureThicknessConfidence',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Failure Thickness Confidence',
  },
  {
    keyword: 'criticalFlawDepthVarType',
    valueType: 0,
    fieldName: 'criticalFlawDepthVarType',
    default: 'sd',
    initializer: undefined,
    type: 'input',
    label: 'Critical Flaw Depth Type',
  },
  {
    keyword: 'criticalFlawDepth',
    valueType: 2,
    fieldName: 'criticalFlawDepth',
    min: 0,
    default: { USCustomary: 0.5, Metric: 12.7 },
    initializer: undefined,
    units: {
      USCustomary: 'in',
      Metric: 'mm',
    },
    type: 'input',
    label: 'Critical Flaw Depth',
  },
  {
    keyword: 'criticalFlawDepthSd',
    valueType: 2,
    fieldName: 'criticalFlawDepthSd',
    min: 0,
    default: { USCustomary: 0.1, Metric: 2.54 },
    initializer: undefined,
    units: {
      USCustomary: 'in',
      Metric: 'mm',
    },
    type: 'input',
    label: 'Critical Flaw Depth SD',
  },
  {
    keyword: 'criticalFlawDepthCv',
    valueType: 2,
    fieldName: 'criticalFlawDepthCv',
    min: 0,
    default: { USCustomary: 0.1, Metric: 2.54 },
    initializer: undefined,
    units: {
      USCustomary: 'in',
      Metric: 'mm',
    },
    type: 'input',
    label: 'Critical Flaw Depth CV',
  },
  {
    keyword: 'criticalFlawDepthConfidence',
    valueType: 0,
    fieldName: 'criticalFlawDepthConfidence',
    default: 'Low',
    initializer: undefined,
    type: 'input',
    label: 'Critical Flaw Depth Confidence',
  },
  {
    keyword: 'bottomType',
    valueType: 0,
    fieldName: 'bottomType',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Bottom Type',
  },
  {
    keyword: 'padType',
    valueType: 0,
    fieldName: 'padType',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Pad Type',
  },
  {
    keyword: 'drainageQuality',
    valueType: 0,
    fieldName: 'drainageQuality',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Drainage Quality',
  },
  {
    keyword: 'cathodicProtection',
    valueType: 0,
    fieldName: 'cathodicProtection',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'CP Quality',
  },
  {
    keyword: 'padRPBs1',
    valueType: 0,
    fieldName: 'padRPBs1',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Does the Pad have RPBs',
  },
  {
    keyword: 'product',
    valueType: 0,
    fieldName: 'product',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Product',
  },
  {
    keyword: 'steamCoil',
    valueType: 0,
    fieldName: 'steamCoil',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Steam Coil',
  },
  {
    keyword: 'waterDrawOff',
    valueType: 0,
    fieldName: 'waterDrawOff',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Water Draw-Off',
  },
  {
    keyword: 'processCorrosionType',
    valueType: 0,
    fieldName: 'processCorrosionType',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Process Corrosion Type',
  },
  {
    keyword: 'cpEffectiveness',
    valueType: 0,
    fieldName: 'cpEffectiveness',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'CP Effectiveness',
  },
  {
    keyword: 'coatingType',
    valueType: 0,
    fieldName: 'coatingType',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Coating Type',
  },
  {
    keyword: 'coatingMaintained',
    valueType: 0,
    fieldName: 'coatingMaintained',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Coating Maintained',
  },
  {
    keyword: 'maxTempExceeded',
    valueType: 0,
    fieldName: 'maxTempExceeded',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Max Rated Temperature is Occasionally Exceeded',
  },
  {
    keyword: 'pwht',
    valueType: 0,
    fieldName: 'pwht',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'PWHT',
  },
  {
    keyword: 'oxidant',
    valueType: 0,
    fieldName: 'oxidant',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Oxidant Present',
  },
  {
    keyword: 'steamedOut',
    valueType: 0,
    fieldName: 'steamedOut',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Steamed Out',
  },
  {
    keyword: 'amineType0',
    valueType: 0,
    fieldName: 'amineType0',
    default: 'Lean',
    initializer: undefined,
    type: 'input',
    label: 'Amine Strength',
  },
  {
    keyword: 'amineType',
    valueType: 0,
    fieldName: 'amineType',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Amine Strength',
  },
  {
    keyword: 'amineType1',
    valueType: 0,
    fieldName: 'amineType1',
    default: 'MEA',
    initializer: undefined,
    type: 'input',
    label: 'Amine Loading',
  },
  {
    keyword: 'sigmaContent',
    valueType: 0,
    fieldName: 'sigmaContent',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Sigma Content',
  },
  {
    keyword: 'amine',
    valueType: 0,
    fieldName: 'amine',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Amine',
  },
  {
    keyword: 'heatTraced',
    valueType: 0,
    fieldName: 'heatTraced',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Heat Traced',
  },
  {
    keyword: 'pwhtAbove1200',
    valueType: 0,
    fieldName: 'pwhtAbove1200',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'PWHT Above 1200',
  },
  {
    keyword: 'above600F',
    valueType: 0,
    fieldName: 'above600F',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Above 600F and Below 1000F',
  },
  {
    keyword: 'o2Present',
    valueType: 0,
    fieldName: 'o2Present',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'O2 Present',
  },
  {
    keyword: 'stainless',
    valueType: 0,
    fieldName: 'stainless',
    default: 'Austenitic',
    initializer: undefined,
    type: 'input',
    label: 'Stainless Steel',
  },
  {
    keyword: 'includeMPTControl',
    valueType: 0,
    fieldName: 'includeMPTControl',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Include a Startup/Shutdown MPT Control',
  },
  {
    keyword: 'carbonSteel',
    valueType: 0,
    fieldName: 'carbonSteel',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Carbon Steel',
  },
  {
    keyword: 'carbonSteel1',
    valueType: 0,
    fieldName: 'carbonSteel1',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Carbon Steel',
  },
  {
    keyword: 'hfPresent',
    valueType: 0,
    fieldName: 'hfPresent',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'HF Present',
  },
  {
    keyword: 'methodStrength',
    valueType: 0,
    fieldName: 'methodStrength',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Method Strength',
  },
  {
    keyword: 'morphology',
    valueType: 0,
    fieldName: 'morphology',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Morphology',
  },
  {
    keyword: 'agl',
    valueType: 2,
    fieldName: 'agl',
    min: 0,
    max: 0.8,
    default: 0.5,
    initializer: undefined,
    type: 'input',
    label: 'Acid Gas Loading',
  },
  {
    keyword: 'weightMethod',
    valueType: 0,
    fieldName: 'weightMethod',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Weight Method',
  },
  {
    keyword: 'nearMarine',
    valueType: 0,
    fieldName: 'nearMarine',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Near Marine',
  },
  {
    keyword: 'coolingTower',
    valueType: 0,
    fieldName: 'coolingTower',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Cooling Tower',
  },
  {
    keyword: 'coolingWaterP2',
    valueType: 0,
    fieldName: 'coolingWaterP2',
    default: 'Recirculating',
    initializer: undefined,
    type: 'input',
    label: 'Cooling Water',
  },
  {
    keyword: 'environment',
    valueType: 0,
    fieldName: 'environment',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Environment',
  },
  {
    keyword: 'frequentWetting',
    valueType: 0,
    fieldName: 'frequentWetting',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Frequent Wetting',
  },
  {
    keyword: 'aerated',
    valueType: 0,
    fieldName: 'aerated',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Aerated',
  },
  {
    keyword: 'depositsPresent',
    valueType: 0,
    fieldName: 'depositsPresent',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Deposits Present',
  },
  {
    keyword: 'cyanides',
    valueType: 0,
    fieldName: 'cyanides',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Cyanides',
  },
  {
    keyword: 'environmentSeverity',
    valueType: 0,
    fieldName: 'environmentSeverity',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Environment Severity',
  },
  {
    keyword: 'equipment',
    valueType: 0,
    fieldName: 'equipment',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Equipment',
  },
  {
    keyword: 'freeWater',
    valueType: 0,
    fieldName: 'freeWater',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Free Water',
  },
  {
    keyword: 'weightMethod1',
    valueType: 0,
    fieldName: 'weightMethod1',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Weight Method',
  },
  {
    keyword: 'monitoring',
    valueType: 0,
    fieldName: 'monitoring',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Monitoring',
  },
  {
    keyword: 'flushedWithWater',
    valueType: 0,
    fieldName: 'flushedWithWater',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Flushed With Water Before Steam Out',
  },
  {
    keyword: 'previousFailures',
    valueType: 0,
    fieldName: 'previousFailures',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Previous Fatigue Failures',
  },
  {
    keyword: 'vibrationSeverity',
    valueType: 0,
    fieldName: 'vibrationSeverity',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Vibration Severity (Visual/Audible)',
  },
  {
    keyword: 'annualShakingFrequency',
    valueType: 0,
    fieldName: 'annualShakingFrequency',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Annual Shaking Frequency',
  },
  {
    keyword: 'cyclicLoadingSource',
    valueType: 0,
    fieldName: 'cyclicLoadingSource',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Cyclic Loading Source',
  },
  {
    keyword: 'branchDesign',
    valueType: 0,
    fieldName: 'branchDesign',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Branch Design',
  },
  {
    keyword: 'correctiveAction',
    valueType: 0,
    fieldName: 'correctiveAction',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Corrective Action Taken',
  },
  {
    keyword: 'pipingComplexity',
    valueType: 0,
    fieldName: 'pipingComplexity',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Piping Complexity',
  },
  {
    keyword: 'pipeCondition',
    valueType: 0,
    fieldName: 'pipeCondition',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Pipe Condition',
  },
  {
    keyword: 'modelConfidenceForDamageFactor',
    valueType: 0,
    fieldName: 'modelConfidenceForDamageFactor',
    default: 'Low',
    initializer: undefined,
    type: 'input',
    label: 'Damage Factor Model Confidence',
  },
  {
    keyword: 'modelConfidenceForCorrosionRate',
    valueType: 0,
    fieldName: 'modelConfidenceForCorrosionRate',
    default: 'Low',
    initializer: undefined,
    type: 'input',
    label: 'Corrosion Rate Model Confidence',
  },
  {
    keyword: 'dobisConfidence',
    valueType: 0,
    fieldName: 'dobisConfidence',
    default: 'Low',
    initializer: undefined,
    type: 'input',
    label: 'Dobis Confidence',
  },
  {
    keyword: 'expertConfidenceDamageFactor',
    valueType: 0,
    fieldName: 'expertConfidenceDamageFactor',
    default: 'Low',
    initializer: undefined,
    type: 'input',
    label: 'Expert Damage Factor Confidence',
  },
  {
    keyword: 'expertConfidenceSusceptibility',
    valueType: 0,
    fieldName: 'expertConfidenceSusceptibility',
    default: 'Low',
    initializer: undefined,
    type: 'input',
    label: 'Expert Susceptibility Confidence',
  },
  {
    keyword: 'expertConfidenceCracks',
    valueType: 0,
    fieldName: 'expertConfidenceCracks',
    default: 'Low',
    initializer: undefined,
    type: 'input',
    label: 'Expert Cracks Confidence',
  },
  {
    keyword: 'expertConfidenceCorrosionRate',
    valueType: 0,
    fieldName: 'expertConfidenceCorrosionRate',
    default: 'Low',
    initializer: undefined,
    type: 'input',
    label: 'Expert Corrosion Rate Confidence',
  },
  {
    keyword: 'expertConfidenceSusceptibilityAndDamageFactor',
    valueType: 0,
    fieldName: 'expertConfidenceSusceptibilityAndDamageFactor',
    default: 'Low',
    initializer: undefined,
    type: 'input',
    label: 'Expert Susceptibility and Damage Factor Confidence',
  },
  {
    keyword: 'expertConfidenceCracksAndSusceptibility',
    valueType: 0,
    fieldName: 'expertConfidenceCracksAndSusceptibility',
    default: 'Low',
    initializer: undefined,
    type: 'input',
    label: 'Expert Cracks and Susceptibility Confidence',
  },
  {
    keyword: 'expertConfidenceSusceptibilityCracksAndDamageFactor',
    valueType: 0,
    fieldName: 'expertConfidenceSusceptibilityCracksAndDamageFactor',
    default: 'Low',
    initializer: undefined,
    type: 'input',
    label: 'Expert Susceptibility, Cracks & Damage Factor Confidence',
  },
  {
    keyword: 'expertConfidenceCracksAndDamageFactor',
    valueType: 0,
    fieldName: 'expertConfidenceCracksAndDamageFactor',
    default: 'Low',
    initializer: undefined,
    type: 'input',
    label: 'Expert Cracks and Damage Factor Confidence',
  },
  {
    keyword: 'expertSusceptibility',
    valueType: 0,
    fieldName: 'expertSusceptibility',
    default: 'Low',
    initializer: undefined,
    type: 'input',
    label: 'Expert Susceptibility',
  },
  {
    keyword: 'expertDamageFactor',
    valueType: 2,
    fieldName: 'expertDamageFactor',
    min: 0,
    max: 5000,
    default: 5,
    initializer: undefined,
    type: 'input',
    label: 'Expert Damage Factor',
  },
  {
    keyword: 'expertCracks',
    valueType: 0,
    fieldName: 'expertCracks',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Expert Cracks',
  },
  {
    keyword: 'mocSufidation',
    valueType: 0,
    fieldName: 'mocSufidation',
    default: 'CS',
    initializer: undefined,
    type: 'input',
    label: 'Material of Construction',
  },
  {
    keyword: 'mocAmineCorrosion',
    valueType: 0,
    fieldName: 'mocAmineCorrosion',
    default: 'cs',
    initializer: undefined,
    type: 'input',
    label: 'Material of Construction',
  },
  {
    keyword: 'mocHFAcidCorrosion',
    valueType: 0,
    fieldName: 'mocHFAcidCorrosion',
    default: 'cs',
    initializer: undefined,
    type: 'input',
    label: 'Material of Construction',
  },
  {
    keyword: 'mocHighTempH2H2SCorrosion',
    valueType: 0,
    fieldName: 'mocHighTempH2H2SCorrosion',
    default: '5Cr',
    initializer: undefined,
    type: 'input',
    label: 'Material of Construction',
  },
  {
    keyword: 'mocHighTempNapthenicCorrosion',
    valueType: 0,
    fieldName: 'mocHighTempNapthenicCorrosion',
    default: '5Cr',
    initializer: undefined,
    type: 'input',
    label: 'Material of Construction',
  },
  {
    keyword: 'mocHydrochloricAcidCorrosion',
    valueType: 0,
    fieldName: 'mocHydrochloricAcidCorrosion',
    default: 'B2_400',
    initializer: undefined,
    type: 'input',
    label: 'Material of Construction',
  },
  {
    keyword: 'mocSulfuricAcidCorrosion',
    valueType: 0,
    fieldName: 'mocSulfuricAcidCorrosion',
    default: '304SS',
    initializer: undefined,
    type: 'input',
    label: 'Material of Construction',
  },
  {
    keyword: 'moc',
    valueType: 0,
    fieldName: 'moc',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Material of Construction',
  },
  {
    keyword: 'moc1',
    valueType: 0,
    fieldName: 'moc1',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Material of Construction',
  },
  {
    keyword: 'moc2',
    valueType: 0,
    fieldName: 'moc2',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Material of Construction',
  },
  {
    keyword: 'moc3',
    valueType: 0,
    fieldName: 'moc3',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Material of Construction',
  },
  {
    keyword: 'moc4',
    valueType: 0,
    fieldName: 'moc4',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Material of Construction',
  },
  {
    keyword: 'moc5',
    valueType: 0,
    fieldName: 'moc5',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Material of Construction',
  },
  {
    keyword: 'exposedToEnvironment',
    valueType: 0,
    fieldName: 'exposedToEnvironment',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Exposed to S, O2, and H2O',
  },
  {
    keyword: 'heatTreatment',
    valueType: 0,
    fieldName: 'heatTreatment',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Heat Treatment',
  },
  {
    keyword: 'mocGrade',
    valueType: 0,
    fieldName: 'mocGrade',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Material of Construction Grade',
  },
  {
    keyword: 'material',
    valueType: 0,
    fieldName: 'material',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Material',
  },
  {
    keyword: 'openClosed',
    valueType: 0,
    fieldName: 'openClosed',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Open or Closed',
  },
  {
    keyword: 'hydrocarbon',
    valueType: 0,
    fieldName: 'hydrocarbon',
    default: 'unknown',
    initializer: undefined,
    type: 'input',
    label: 'Hydrocarbon Type',
  },
  {
    keyword: 'glycolFactor',
    valueType: 2,
    fieldName: 'glycolFactor',
    min: 0,
    max: 1,
    default: 0.5,
    initializer: undefined,
    type: 'input',
    label: 'Glycol Factor',
  },
  {
    keyword: 'inhibitorFactor',
    valueType: 2,
    fieldName: 'inhibitorFactor',
    min: 0,
    max: 1,
    default: 0.5,
    initializer: undefined,
    type: 'input',
    label: 'Inhibitor Factor',
  },
  {
    keyword: 'coatingAge',
    valueType: 2,
    fieldName: 'coatingAge',
    min: 0,
    default: 10,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'input',
    label: 'Coating Age',
  },
  {
    keyword: 'componentAge',
    valueType: 2,
    fieldName: 'componentAge',
    min: 0,
    default: 20,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'input',
    label: 'Component Age',
  },
  {
    keyword: 'pastJobTimeInService',
    valueType: 2,
    fieldName: 'pastJobTimeInService',
    min: 0,
    default: 10,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'input',
    label: 'Time in Service for Past Job',
  },
  {
    keyword: 'gff',
    valueType: 2,
    fieldName: 'gff',
    min: 1e-5,
    max: 1e-3,
    default: 1e-4,
    initializer: undefined,
    type: 'input',
    label: 'Generic Failure Frequency',
  },
  {
    keyword: 'flowShearStress',
    valueType: 2,
    fieldName: 'flowShearStress',
    min: { USCustomary: 0.0, Metric: 0.0 },
    max: { USCustomary: 0.0145, Metric: 100.0 },
    default: { USCustomary: 0.00145, Metric: 10.0 },
    units: {
      USCustomary: 'psi',
      Metric: 'Pa',
    },
    initializer: undefined,
    type: 'input',
    label: 'Flow Shear Stress',
  },
  {
    keyword: 'h2sPressure',
    valueType: 2,
    fieldName: 'h2sPressure',
    min: { USCustomary: 0.0, Metric: 0.0 },
    max: { USCustomary: 300.0, Metric: 20.68 },
    default: { USCustomary: 50.0, Metric: 3.44738 },
    units: {
      USCustomary: 'psi',
      Metric: 'bar',
    },
    initializer: undefined,
    type: 'input',
    label: 'H2S Partial Pressure',
  },
  {
    keyword: 'h2PartialPressure',
    valueType: 2,
    fieldName: 'h2PartialPressure',
    min: { USCustomary: 0.0, Metric: 0.0 },
    max: { USCustomary: 100.0, Metric: 6.89 },
    default: { USCustomary: 50.0, Metric: 3.44738 },
    units: {
      USCustomary: 'psi',
      Metric: 'bar',
    },
    initializer: undefined,
    type: 'input',
    label: 'H2S Partial Pressure',
  },
  {
    keyword: 'acidTemp',
    valueType: 2,
    fieldName: 'acidTemp',
    min: { USCustomary: 0.0, Metric: -17.77 },
    max: { USCustomary: 250.0, Metric: 121.111 },
    default: { USCustomary: 150.0, Metric: 65.5556 },
    initializer: undefined,
    units: {
      USCustomary: 'F',
      Metric: 'C',
    },
    type: 'input',
    label: 'Acid Temperature',
  },
  {
    keyword: 'acidConc',
    valueType: 2,
    fieldName: 'acidConc',
    min: { USCustomary: 0.0, Metric: 0.0 },
    max: { USCustomary: 100.0, Metric: 100.0 },
    default: { USCustomary: 60.0, Metric: 60.0 },
    units: {
      USCustomary: 'wt%',
      Metric: 'wt%',
    },
    initializer: undefined,
    type: 'input',
    label: 'Acid Concentration',
  },
  {
    keyword: 'acidVelocity',
    valueType: 2,
    fieldName: 'acidVelocity',
    min: { USCustomary: 0.0, Metric: 0 },
    max: { USCustomary: 15.0, Metric: 4.57 },
    default: { USCustomary: 7.5, Metric: 2.3 },
    initializer: undefined,
    units: {
      USCustomary: 'ft/sec',
      Metric: 'm/sec',
    },
    type: 'input',
    label: 'Acid Velocity',
  },
  {
    keyword: 'fugacity',
    valueType: 2,
    fieldName: 'fugacity',
    min: { USCustomary: 0.0, Metric: 0.0 },
    max: { USCustomary: 1450.0, Metric: 100.0 },
    default: { USCustomary: 145.0, Metric: 10.0 },
    units: {
      USCustomary: 'psi',
      Metric: 'bar',
    },
    initializer: undefined,
    type: 'input',
    label: 'Fugacity',
  },
  {
    keyword: 'branchDiameter',
    valueType: 2,
    fieldName: 'branchDiameter',
    min: { USCustomary: 0.0, Metric: 0.0 },
    default: { USCustomary: 1.0, Metric: 25.4 },
    units: {
      USCustomary: 'in',
      Metric: 'mm',
    },
    initializer: undefined,
    type: 'input',
    label: 'Branch Diameter',
  },
  {
    keyword: 'componentThickness',
    valueType: 2,
    fieldName: 'componentThickness',
    min: { USCustomary: 0.0, Metric: 0.0 },
    max: { USCustomary: 5.0, Metric: 127.0 },
    default: { USCustomary: 0.25, Metric: 6.35 },
    units: {
      USCustomary: 'in',
      Metric: 'mm',
    },
    initializer: undefined,
    type: 'input',
    label: 'Component Thickness',
  },
  {
    keyword: 'concentration',
    valueType: 2,
    fieldName: 'concentration',
    min: { USCustomary: 0.0, Metric: 0.0 },
    max: { USCustomary: 16.0, Metric: 16.0 },
    default: { USCustomary: 5.0, Metric: 5.0 },
    units: {
      USCustomary: 'wt%',
      Metric: 'wt%',
    },
    initializer: undefined,
    type: 'input',
    label: 'NH4HS Concentration',
  },
  {
    keyword: 'tan1',
    valueType: 2,
    fieldName: 'tan1',
    min: { USCustomary: 0.0, Metric: 0.0 },
    max: { USCustomary: 4.0, Metric: 4.0 },
    default: { USCustomary: 1.0, Metric: 1.0 },
    initializer: undefined,
    type: 'input',
    label: 'Tan',
  },
  {
    keyword: 'tan2',
    valueType: 2,
    fieldName: 'tan2',
    min: { USCustomary: 3.0, Metric: 3.0 },
    max: { USCustomary: 7.0, Metric: 7.0 },
    default: { USCustomary: 5.0, Metric: 5.0 },
    initializer: undefined,
    type: 'input',
    label: 'Tan',
  },
  {
    keyword: 'sulfurConcentration',
    valueType: 2,
    fieldName: 'sulfurConcentration',
    min: { USCustomary: 0.0, Metric: 0.0 },
    max: { USCustomary: 4.0, Metric: 4.0 },
    default: { USCustomary: 1.0, Metric: 1.0 },
    units: {
      USCustomary: 'wt%',
      Metric: 'wt%',
    },
    initializer: undefined,
    type: 'input',
    label: 'Sulfer Concentration',
  },
  {
    keyword: 'sulfurConcentration1',
    valueType: 2,
    fieldName: 'sulfurConcentration1',
    min: { USCustomary: 0.0, Metric: 0.0 },
    max: { USCustomary: 10.0, Metric: 10.0 },
    default: { USCustomary: 1.0, Metric: 1.0 },
    units: {
      USCustomary: 'wt%',
      Metric: 'wt%',
    },
    initializer: undefined,
    type: 'input',
    label: 'Sulfer Concentration',
  },
  {
    keyword: 'resistivity',
    valueType: 2,
    fieldName: 'resistivity',
    default: 750,
    initializer: undefined,
    type: 'input',
    label: 'Resistivity',
  },
  {
    keyword: 'soilResistivity',
    valueType: 2,
    fieldName: 'soilResistivity',
    default: 750,
    initializer: undefined,
    type: 'input',
    label: 'Soil Resistivity',
  },
  {
    keyword: 'brinnellHardness',
    valueType: 2,
    fieldName: 'brinnellHardness',
    min: 100,
    max: 300,
    default: 150,
    initializer: undefined,
    type: 'input',
    label: 'Brinnell Hardness',
  },
  {
    keyword: 'h2sconcentration',
    valueType: 2,
    fieldName: 'h2sconcentration',
    min: { USCustomary: 0.0, Metric: 0.0 },
    max: { USCustomary: 1.2, Metric: 1.2 },
    default: { USCustomary: 0.5, Metric: 0.5 },
    units: {
      USCustomary: 'mol%',
      Metric: 'mol%',
    },
    initializer: undefined,
    type: 'input',
    label: 'H2S Concentration',
  },
  {
    keyword: 'h2sConcentration1',
    valueType: 2,
    fieldName: 'h2sConcentration1',
    min: { USCustomary: 0.0, Metric: 0.0 },
    default: { USCustomary: 10.0, Metric: 10.0 },
    units: {
      USCustomary: 'ppm',
      Metric: 'ppm',
    },
    initializer: undefined,
    type: 'input',
    label: 'H2S Concentration in Water',
  },
  {
    keyword: 'causticConcentration',
    valueType: 2,
    fieldName: 'causticConcentration',
    min: { USCustomary: 0.0, Metric: 0.0 },
    max: { USCustomary: 50.0, Metric: 50.0 },
    default: { USCustomary: 5.0, Metric: 5.0 },
    units: {
      USCustomary: 'wt%',
      Metric: 'wt%',
    },
    initializer: undefined,
    type: 'input',
    label: 'Caustic Concentration',
  },
  {
    keyword: 'amineConcentration',
    valueType: 2,
    fieldName: 'amineConcentration',
    min: 0,
    max: 100,
    default: 5,
    initializer: undefined,
    type: 'input',
    label: 'Amine Concentration',
  },
  {
    keyword: 'timeInService',
    valueType: 2,
    fieldName: 'timeInService',
    min: 0,
    max: 20,
    default: 5,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'input',
    label: 'Time in Service',
  },
  {
    keyword: 'maxNumberInitSites',
    valueType: 2,
    fieldName: 'maxNumberInitSites',
    min: 0,
    max: 1000,
    default: 5,
    initializer: undefined,
    type: 'input',
    label: 'Max Number Initiation Sites',
  },
  {
    keyword: 'componentArea',
    valueType: 2,
    fieldName: 'componentArea',
    min: { USCustomary: 0.0, Metric: 0.0 },
    max: { USCustomary: 100.0, Metric: 9.3 },
    default: { USCustomary: 5.0, Metric: 0.5 },
    initializer: undefined,
    units: {
      USCustomary: 'ft^2',
      Metric: 'm^2',
    },
    type: 'input',
    label: 'Component Area',
  },
  {
    keyword: 'deltaT',
    valueType: 2,
    fieldName: 'deltaT',
    min: { USCustomary: -120.0, Metric: -84.4 },
    max: { USCustomary: 120.0, Metric: 48.5 },
    default: { USCustomary: 0.0, Metric: -18.0 },
    initializer: undefined,
    units: {
      USCustomary: 'F',
      Metric: 'C',
    },
    type: 'input',
    label: 'Delta Temperature',
  },
  {
    keyword: 'nelsonCurveDeltaT',
    valueType: 2,
    fieldName: 'nelsonCurveDeltaT',
    min: { USCustomary: -150.0, Metric: -101.0 },
    max: { USCustomary: 100.0, Metric: 37.75 },
    default: { USCustomary: 0.0, Metric: -18.0 },
    initializer: undefined,
    units: {
      USCustomary: 'F',
      Metric: 'C',
    },
    type: 'input',
    label: 'Nelson Curve Delta T',
  },
  {
    keyword: 'maxMetalTemp',
    valueType: 2,
    fieldName: 'maxMetalTemp',
    min: { USCustomary: 900.0, Metric: 482.3 },
    max: { USCustomary: 2100.0, Metric: 1150.0 },
    default: { USCustomary: 1000.0, Metric: 538.0 },
    initializer: undefined,
    units: {
      USCustomary: 'F',
      Metric: 'C',
    },
    type: 'input',
    label: 'Maximum Metal Temperature',
  },
  {
    keyword: 'carbonateConcentration',
    valueType: 2,
    fieldName: 'carbonateConcentration',
    min: { USCustomary: 0.0, Metric: 0.0 },
    default: { USCustomary: 25.0, Metric: 25.0 },
    initializer: undefined,
    units: {
      USCustomary: 'ppm',
      Metric: 'ppm',
    },
    type: 'input',
    label: 'Carbonate Concentration',
  },
  {
    keyword: 'clConcentration',
    valueType: 2,
    fieldName: 'clConcentration',
    min: { USCustomary: 0.0, Metric: 0.0 },
    default: { USCustomary: 25.0, Metric: 25.0 },
    initializer: undefined,
    units: {
      USCustomary: 'ppb',
      Metric: 'ppb',
    },
    type: 'input',
    label: 'CL Concentration',
  },
  {
    keyword: 'clConcentration1',
    valueType: 2,
    fieldName: 'clConcentration1',
    min: { USCustomary: 0.0, Metric: 0.0 },
    max: { USCustomary: 1.0, Metric: 1.0 },
    default: { USCustomary: 0.5, Metric: 0.5 },
    initializer: undefined,
    units: {
      USCustomary: 'ppb',
      Metric: 'ppb',
    },
    type: 'input',
    label: 'CL Concentration',
  },
  {
    keyword: 'oxygen',
    valueType: 2,
    fieldName: 'oxygen',
    min: { USCustomary: 0.0, Metric: 0.0 },
    default: { USCustomary: 25.0, Metric: 25.0 },
    initializer: undefined,
    units: {
      USCustomary: 'ppb',
      Metric: 'ppb',
    },
    type: 'input',
    label: 'Oxygen Concentration',
  },
  {
    keyword: 'nSRatio',
    valueType: 2,
    fieldName: 'nSRatio',
    min: 0,
    default: 200,
    initializer: undefined,
    type: 'input',
    label: 'N(ppm)/S(wt% x 100) Ratio',
  },
  {
    keyword: 'steelSulfur',
    valueType: 2,
    fieldName: 'steelSulfur',
    min: 0,
    max: 1,
    default: 0.1,
    units: {
      USCustomary: '%',
      Metric: '%',
    },
    initializer: undefined,
    type: 'input',
    label: 'Water pH',
  },
  {
    keyword: 'waterPh',
    valueType: 2,
    fieldName: 'waterPh',
    min: 3,
    max: 10,
    default: 7.0,
    initializer: undefined,
    type: 'input',
    label: 'Water pH',
  },
  {
    keyword: 'pH',
    valueType: 2,
    fieldName: 'pH',
    min: 0,
    max: 10,
    default: 7.0,
    initializer: undefined,
    type: 'input',
    label: 'pH',
  },
  {
    keyword: 'pH1',
    valueType: 2,
    fieldName: 'pH1',
    min: 7,
    max: 10,
    default: 7.0,
    initializer: undefined,
    type: 'input',
    label: 'pH',
  },
  {
    keyword: 'pH2',
    valueType: 2,
    fieldName: 'pH2',
    min: 1,
    max: 12,
    default: 7.0,
    initializer: undefined,
    type: 'input',
    label: 'pH',
  },
  {
    keyword: 'pH3',
    valueType: 2,
    fieldName: 'pH3',
    min: 3,
    max: 7,
    default: 7.0,
    initializer: undefined,
    type: 'input',
    label: 'pH',
  },
  {
    keyword: 'pH4',
    valueType: 2,
    fieldName: 'pH4',
    min: 0,
    max: 7,
    default: 7.0,
    initializer: undefined,
    type: 'input',
    label: 'pH',
  },
  {
    keyword: 'rsi',
    valueType: 2,
    fieldName: 'rsi',
    default: 2.0,
    initializer: undefined,
    type: 'input',
    label: 'rsi',
  },
  {
    keyword: 'temperatureSulidationDM',
    valueType: 2,
    fieldName: 'temperatureSulidationDM',
    min: { USCustomary: 500.0, Metric: 260.0 },
    max: { USCustomary: 800.0, Metric: 426.65 },
    default: { USCustomary: 600.0, Metric: 315.0 },
    initializer: undefined,
    units: {
      USCustomary: 'F',
      Metric: 'C',
    },
    type: 'input',
    label: 'Temperature',
  },
  {
    keyword: 'temperature',
    valueType: 2,
    fieldName: 'temperature',
    min: { USCustomary: 0.0, Metric: -17.77 },
    max: { USCustomary: 500.0, Metric: 260.0 },
    default: { USCustomary: 200.0, Metric: 93.0 },
    initializer: undefined,
    units: {
      USCustomary: 'F',
      Metric: 'C',
    },
    type: 'input',
    label: 'Temperature',
  },
  {
    keyword: 'temperature1',
    valueType: 2,
    fieldName: 'temperature1',
    min: { USCustomary: 0.0, Metric: -17.77 },
    max: { USCustomary: 210.0, Metric: 98.8 },
    default: { USCustomary: 200.0, Metric: 93.0 },
    initializer: undefined,
    units: {
      USCustomary: 'F',
      Metric: 'C',
    },
    type: 'input',
    label: 'Temperature',
  },
  {
    keyword: 'temperature2',
    valueType: 2,
    fieldName: 'temperature2',
    min: { USCustomary: 400.0, Metric: 204.5 },
    max: { USCustomary: 1000.0, Metric: 537.8 },
    default: { USCustomary: 500.0, Metric: 260.0 },
    initializer: undefined,
    units: {
      USCustomary: 'F',
      Metric: 'C',
    },
    type: 'input',
    label: 'Temperature',
  },
  {
    keyword: 'temperature3',
    valueType: 2,
    fieldName: 'temperature3',
    default: { USCustomary: 200.0, Metric: 93.0 },
    initializer: undefined,
    units: {
      USCustomary: 'F',
      Metric: 'C',
    },
    type: 'input',
    label: 'Temperature',
  },
  {
    keyword: 'temperature4',
    valueType: 2,
    fieldName: 'temperature4',
    default: { USCustomary: 200.0, Metric: 93.0 },
    initializer: undefined,
    units: {
      USCustomary: 'F',
      Metric: 'C',
    },
    type: 'input',
    label: 'Soil Temperature',
  },
  {
    keyword: 'temperature5',
    valueType: 2,
    fieldName: 'temperature5',
    default: { USCustomary: 200.0, Metric: 93.0 },
    initializer: undefined,
    units: {
      USCustomary: 'F',
      Metric: 'C',
    },
    type: 'input',
    label: 'Operating Temperature',
  },
  {
    keyword: 'hfConcentration',
    valueType: 2,
    fieldName: 'hfConcentration',
    min: 0,
    max: 100,
    default: { USCustomary: 10.0, Metric: 10.0 },
    initializer: undefined,
    units: {
      USCustomary: '%',
      Metric: '%',
    },
    type: 'input',
    label: 'HF in Water Concentration',
  },
  {
    keyword: 'operatingTemperature',
    valueType: 2,
    fieldName: 'operatingTemperature',
    min: { USCustomary: 150.0, Metric: 65 },
    max: { USCustomary: 300.0, Metric: 148.88 },
    default: { USCustomary: 200.0, Metric: 93.0 },
    initializer: undefined,
    units: {
      USCustomary: 'F',
      Metric: 'C',
    },
    type: 'input',
    label: 'Operating Temperature',
  },
  {
    keyword: 'flowVelocity',
    valueType: 2,
    fieldName: 'flowVelocity',
    min: { USCustomary: 0.0, Metric: 0 },
    max: { USCustomary: 30.0, Metric: 9.0 },
    default: { USCustomary: 7.5, Metric: 2.3 },
    initializer: undefined,
    units: {
      USCustomary: 'ft/sec',
      Metric: 'm/sec',
    },
    type: 'input',
    label: 'Flow Velocity',
  },
  {
    keyword: 'velocity1',
    valueType: 2,
    fieldName: 'velocity1',
    min: { USCustomary: 0.0, Metric: 0 },
    max: { USCustomary: 50.0, Metric: 15.5 },
    default: { USCustomary: 7.5, Metric: 2.3 },
    initializer: undefined,
    units: {
      USCustomary: 'ft/sec',
      Metric: 'm/sec',
    },
    type: 'input',
    label: 'Velocity',
  },
  {
    keyword: 'velocity2',
    valueType: 2,
    fieldName: 'velocity2',
    min: { USCustomary: 0.0, Metric: 0 },
    default: { USCustomary: 7.5, Metric: 2.3 },
    initializer: undefined,
    units: {
      USCustomary: 'ft/sec',
      Metric: 'm/sec',
    },
    type: 'input',
    label: 'Velocity',
  },
  {
    keyword: 'expertCorrosionRate0',
    valueType: 2,
    fieldName: 'expertCorrosionRate0',
    min: { USCustomary: 0.0, Metric: 0.0 },
    max: { USCustomary: 1000.0, Metric: 25.4 },
    default: { USCustomary: 10.0, Metric: 0.25 },
    initializer: undefined,
    units: {
      USCustomary: 'mil/yr',
      Metric: 'mm/yr',
    },
    type: 'input',
    label: 'Expert Corrosion Rate',
  },
  {
    keyword: 'expertDamageFactorSD',
    valueType: 2,
    fieldName: 'expertDamageFactorSD',
    min: 0,
    default: 0.1,
    initializer: undefined,
    type: 'input',
    label: 'Expert Damage Factor Standard Deviation',
  },
  {
    keyword: 'expertCorrosionRateSD',
    valueType: 2,
    fieldName: 'expertCorrosionRateSD',
    min: 0,
    default: 0.1,
    initializer: undefined,
    type: 'input',
    label: 'Expert Corrosion Rate Standard Deviation',
  },
  {
    keyword: 'expertCorrosionRate',
    valueType: 2,
    fieldName: 'expertCorrosionRate',
    min: { USCustomary: 0.0, Metric: 0.0 },
    max: { USCustomary: 500.0, Metric: 12.7 },
    default: { USCustomary: 10.0, Metric: 0.25 },
    initializer: undefined,
    units: {
      USCustomary: 'mil/yr',
      Metric: 'mm/yr',
    },
    type: 'input',
    label: 'Expert Corrosion Rate',
  },
  {
    keyword: 'expertCorrosionRate1',
    valueType: 2,
    fieldName: 'expertCorrosionRate1',
    min: { USCustomary: 0.0, Metric: 0.0 },
    max: { USCustomary: 5.0, Metric: 0.127 },
    default: { USCustomary: 1.0, Metric: 0.0254 },
    initializer: undefined,
    units: {
      USCustomary: 'mil/yr',
      Metric: 'mm/yr',
    },
    type: 'input',
    label: 'Expert Corrosion Rate',
  },
  {
    keyword: 'expertCorrosionRate2',
    valueType: 2,
    fieldName: 'expertCorrosionRate2',
    min: { USCustomary: 0.0, Metric: 0.0 },
    max: { USCustomary: 10.0, Metric: 0.254 },
    default: { USCustomary: 1.0, Metric: 0.0254 },
    initializer: undefined,
    units: {
      USCustomary: 'mil/yr',
      Metric: 'mm/yr',
    },
    type: 'input',
    label: 'Expert Corrosion Rate',
  },
  {
    keyword: 'hsas',
    valueType: 2,
    fieldName: 'hsas',
    min: 0,
    max: 6,
    default: 1.0,
    initializer: undefined,
    type: 'input',
    label: 'Heat Stables Salts',
  },
  {
    keyword: 'includeCorrosionRateExpertPriors',
    valueType: 1,
    fieldName: 'includeCorrosionRateExpertPriors',
    default: false,
    initializer: undefined,
    type: 'input',
    label: 'Flag to include expert corrosion rate priors',
    tooltip: 'Flag to include expert corrosion rate priors',
    transform: (value) => {
      if(value === true || value === 'true') {
        return 1
      }
      return 0
    },
    inverseTransform: (value) => {
      if(value === '1' || value === 1) {
        return true
      }
      return false
    },
  },
  {
    keyword: 'includeCrackingExpertPriors',
    valueType: 1,
    fieldName: 'includeCrackingExpertPriors',
    default: false,
    initializer: undefined,
    type: 'input',
    label: 'Flag to include expert cracking priors',
    tooltip: 'Flag to include expert cracking priors',
    transform: (value) => {
      if(value === true || value === 'true') {
        return 1
      }
      return 0
    },
    inverseTransform: (value) => {
      if(value === 1) {
        return true
      }
      return false
    },
  },
  // **************************************************************************************
  // **************************************************************************************
  //                                      Outputs
  // **************************************************************************************
  // **************************************************************************************
  // *****************************************************
  // Problem Type 1 - Cracking
  // *****************************************************
  {
    keyword: 'crackingProb1Labels',
    valueType: 0,
    fieldName: 'crackingProb1Labels',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'crackingProb1ProbYes',
    valueType: 2,
    fieldName: 'crackingProb1ProbYes',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  // *****************************************************
  // Problem Type 1 - Thinning
  // *****************************************************
  {
    keyword: 'localThinningProb',
    valueType: 2,
    fieldName: 'localThinningProb',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: '%',
      Metric: '%',
    },
    type: 'output',
    label: 'Local Thinning Probability',
  },
  {
    keyword: 'thinningProb1Labels',
    valueType: 0,
    fieldName: 'thinningProb1Labels',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'thinningProb1ProbYes',
    valueType: 2,
    fieldName: 'thinningProb1ProbYes',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  // *****************************************************
  // Problem Type 2
  // *****************************************************
  {
    keyword: 'baseFailureTimeEV',
    valueType: 2,
    fieldName: 'baseFailureTimeEV',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'output',
    label: 'Base Failure Time EV',
  },
  {
    keyword: 'failureTimeEV',
    valueType: 2,
    fieldName: 'failureTimeEV',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'output',
    label: 'Failure Time EV',
  },
  {
    keyword: 'failureTimeEVPrev',
    valueType: 2,
    fieldName: 'failureTimeEVPrev',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'output',
    label: 'Failure Time EV',
  },
  {
    keyword: 'failureTimeEVCurr',
    valueType: 2,
    fieldName: 'failureTimeEVCurr',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'output',
    label: 'Failure Time EV',
  },
  {
    keyword: 'baseFailureTimeSD',
    valueType: 2,
    fieldName: 'baseFailureTimeSD',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'output',
    label: 'Base Failure Time SD',
  },
  {
    keyword: 'failureTimeSD',
    valueType: 2,
    fieldName: 'failureTimeSD',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'output',
    label: 'Failure Time SD',
  },
  {
    keyword: 'failureTimeSDPrev',
    valueType: 2,
    fieldName: 'failureTimeSDPrev',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'output',
    label: 'Failure Time SD',
  },
  {
    keyword: 'failureTimeSDCurr',
    valueType: 2,
    fieldName: 'failureTimeSDCurr',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'output',
    label: 'Failure Time SD',
  },
  {
    keyword: 'corrosionRateEV',
    valueType: 2,
    fieldName: 'corrosionRateEV',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'mil/yr',
      Metric: 'mm/yr',
    },
    type: 'output',
    label: 'Corrosion Rate EV',
  },
  {
    keyword: 'corrosionRateSD',
    valueType: 2,
    fieldName: 'corrosionRateSD',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'mil/yr',
      Metric: 'mm/yr',
    },
    type: 'output',
    label: 'Corrosion Rate SD',
  },
  {
    keyword: 'corrosionRateEVPrev',
    valueType: 2,
    fieldName: 'corrosionRateEVPrev',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'mil/yr',
      Metric: 'mm/yr',
    },
    type: 'output',
    label: 'Corrosion Rate EV',
  },
  {
    keyword: 'corrosionRateSDPrev',
    valueType: 2,
    fieldName: 'corrosionRateSDPrev',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'mil/yr',
      Metric: 'mm/yr',
    },
    type: 'output',
    label: 'Corrosion Rate SD',
  },
  {
    keyword: 'corrosionRateEVCurr',
    valueType: 2,
    fieldName: 'corrosionRateEVCurr',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'mil/yr',
      Metric: 'mm/yr',
    },
    type: 'output',
    label: 'Corrosion Rate EV',
  },
  {
    keyword: 'corrosionRateSDCurr',
    valueType: 2,
    fieldName: 'corrosionRateSDCurr',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'mil/yr',
      Metric: 'mm/yr',
    },
    type: 'output',
    label: 'Corrosion Rate SD',
  },
  {
    keyword: 'baseCorrosionRateEV',
    valueType: 2,
    fieldName: 'baseCorrosionRateEV',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'mil/yr',
      Metric: 'mm/yr',
    },
    type: 'output',
    label: 'Base Corrosion Rate EV',
  },
  {
    keyword: 'baseCorrosionRateSD',
    valueType: 2,
    fieldName: 'baseCorrosionRateSD',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 'mil/yr',
      Metric: 'mm/yr',
    },
    type: 'output',
    label: 'Base Corrosion Rate SD',
  },
  {
    keyword: 'damageFactorEV',
    valueType: 2,
    fieldName: 'damageFactorEV',
    default: undefined,
    initializer: undefined,
    type: 'output',
    label: 'Damage Factor EV',
  },
  {
    keyword: 'damageFactorSD',
    valueType: 2,
    fieldName: 'damageFactorSD',
    default: undefined,
    initializer: undefined,
    type: 'output',
    label: 'Damage Factor SD',
  },
  {
    keyword: 'susceptibilityEV',
    valueType: 2,
    fieldName: 'susceptibilityEV',
    default: undefined,
    initializer: undefined,
    type: 'output',
    label: 'Susceptibility EV',
  },
  {
    keyword: 'susceptibilitySD',
    valueType: 2,
    fieldName: 'susceptibilitySD',
    default: undefined,
    initializer: undefined,
    type: 'output',
    label: 'Susceptibility SD',
  },
  {
    keyword: 'cracksProbYes',
    valueType: 2,
    fieldName: 'cracksProbYes',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: '%',
      Metric: '%',
    },
    type: 'output',
    label: 'Cracks Probability Yes',
  },
  {
    keyword: 'cracksProbNo',
    valueType: 2,
    fieldName: 'cracksProbNo',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: '%',
      Metric: '%',
    },
    type: 'output',
    label: 'Cracks Probability No',
  },
  {
    keyword: 'survivalPof',
    valueType: 2,
    fieldName: 'survivalPof',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: '%',
      Metric: '%',
    },
    type: 'output',
    label: 'Conditional Survival POF',
  },
  {
    keyword: 'survivalRisk',
    valueType: 2,
    fieldName: 'survivalRisk',
    default: undefined,
    initializer: undefined,
    type: 'output',
    label: 'Conditional Survival Risk',
  },
  {
    keyword: 'failureProbYes',
    valueType: 2,
    fieldName: 'failureProbYes',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: '%',
      Metric: '%',
    },
    type: 'output',
    label: 'Failure Probability Yes',
  },
  {
    keyword: 'failureProbYesPrev',
    valueType: 2,
    fieldName: 'failureProbYesPrev',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: '%',
      Metric: '%',
    },
    type: 'output',
    label: 'Failure Probability Yes',
  },
  {
    keyword: 'failureProbYesCurr',
    valueType: 2,
    fieldName: 'failureProbYesCurr',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: '%',
      Metric: '%',
    },
    type: 'output',
    label: 'Failure Probability Yes',
  },
  {
    keyword: 'baseFailureProbYes',
    valueType: 2,
    fieldName: 'baseFailureProbYes',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: '%',
      Metric: '%',
    },
    type: 'output',
    label: 'Base Failure Probability Yes',
  },
  {
    keyword: 'risk',
    valueType: 2,
    fieldName: 'risk',
    default: undefined,
    initializer: undefined,
    type: 'output',
    label: 'Final Risk',
  },
  {
    keyword: 'riskPrev',
    valueType: 2,
    fieldName: 'riskPrev',
    default: undefined,
    initializer: undefined,
    type: 'output',
    label: 'Previous Risk',
  },
  {
    keyword: 'riskCurr',
    valueType: 2,
    fieldName: 'riskCurr',
    default: undefined,
    initializer: undefined,
    type: 'output',
    label: 'Current Risk',
  },
  {
    keyword: 'riskBase',
    valueType: 2,
    fieldName: 'riskBase',
    default: undefined,
    initializer: undefined,
    type: 'output',
    label: 'Base Risk',
  },
  {
    keyword: 'failureProbNo',
    valueType: 2,
    fieldName: 'failureProbNo',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: '%',
      Metric: '%',
    },
    type: 'output',
    label: 'Failure Probability No',
  },
  {
    keyword: 'failureProbNoPrev',
    valueType: 2,
    fieldName: 'failureProbNoPrev',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: '%',
      Metric: '%',
    },
    type: 'output',
    label: 'Failure Probability No',
  },
  {
    keyword: 'failureProbNoCurr',
    valueType: 2,
    fieldName: 'failureProbNoCurr',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: '%',
      Metric: '%',
    },
    type: 'output',
    label: 'Failure Probability No',
  },
  {
    keyword: 'baseFailureProbNo',
    valueType: 2,
    fieldName: 'baseFailureProbNo',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: '%',
      Metric: '%',
    },
    type: 'output',
    label: 'Base Failure Probability No',
  },
  {
    keyword: 'corrosionRateStates',
    valueType: 0,
    fieldName: 'corrosionRateStates',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'corrosionRateStatesCurr',
    valueType: 0,
    fieldName: 'corrosionRateStatesCurr',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'corrosionRateStatesPrev',
    valueType: 0,
    fieldName: 'corrosionRateStatesPrev',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'baseCorrosionRateStates',
    valueType: 0,
    fieldName: 'baseCorrosionRateStates',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'damageFactorStates',
    valueType: 0,
    fieldName: 'damageFactorStates',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'susceptibilityStates',
    valueType: 0,
    fieldName: 'susceptibilityStates',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'cracksStates',
    valueType: 0,
    fieldName: 'cracksStates',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'failureTimeStatesCurr',
    valueType: 0,
    fieldName: 'failureTimeStatesCurr',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'failureTimeStatesPrev',
    valueType: 0,
    fieldName: 'failureTimeStatesPrev',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'failureTimeStates',
    valueType: 0,
    fieldName: 'failureTimeStates',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'baseFailureTimeStates',
    valueType: 0,
    fieldName: 'baseFailureTimeStates',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'failureStatesCurr',
    valueType: 0,
    fieldName: 'failureStatesCurr',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'failureStatesPrev',
    valueType: 0,
    fieldName: 'failureStatesPrev',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'failureStates',
    valueType: 0,
    fieldName: 'failureStates',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'baseFailureStates',
    valueType: 0,
    fieldName: 'baseFailureStates',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  // *****************************************************
  // Json File Related
  // *****************************************************
  {
    keyword: 'whichNetworkSelect',
    valueType: 0,
    fieldName: 'whichNetworkSelect',
    default: '0',
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'plotDamageFactorBool',
    valueType: 0,
    fieldName: 'plotDamageFactorBool',
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonOutAll_Labels',
    valueType: 0,
    fieldName: 'poppedJsonOutAll_Labels',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonOutAll',
    valueType: 0,
    fieldName: 'poppedJsonOutAll',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'poppedJsonOut',
    valueType: 0,
    fieldName: 'poppedJsonOut',
    default: undefined,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'previousOutFlag',
    valueType: 1,
    fieldName: 'previousOutFlag',
    default: 0,
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'currentOutFlag',
    valueType: 1,
    fieldName: 'currentOutFlag',
    default: 0,
    initializer: undefined,
    type: 'output',
  },
  // {
  //   keyword: 'poppedJsonOutThinning01',
  //   valueType: 0,
  //   fieldName: 'poppedJsonOutThinning01',
  //   default: undefined,
  //   initializer: undefined,
  //   type: 'output',
  // },
  // {
  //   keyword: 'poppedJsonOutCracking01',
  //   valueType: 0,
  //   fieldName: 'poppedJsonOutCracking01',
  //   default: undefined,
  //   initializer: undefined,
  //   type: 'output',
  // },
  // {
  //   keyword: 'poppedJsonOutSulifidation01',
  //   valueType: 0,
  //   fieldName: 'poppedJsonOutSulifidation01',
  //   default: undefined,
  //   initializer: undefined,
  //   type: 'output',
  // },
  // {
  //   keyword: 'poppedJsonOutAmineCracking01',
  //   valueType: 0,
  //   fieldName: 'poppedJsonOutAmineCracking01',
  //   default: undefined,
  //   initializer: undefined,
  //   type: 'output',
  // },
  // {
  //   keyword: 'poppedJsonOutAmineCorrosion01',
  //   valueType: 0,
  //   fieldName: 'poppedJsonOutAmineCorrosion01',
  //   default: undefined,
  //   initializer: undefined,
  //   type: 'output',
  // },
  // {
  //   keyword: 'poppedJsonOut885Embrittlement01',
  //   valueType: 0,
  //   fieldName: 'poppedJsonOut885Embrittlement01',
  //   default: undefined,
  //   initializer: undefined,
  //   type: 'output',
  // },
  // {
  //   keyword: 'poppedJsonOutAcidSourWaterCorrosion01',
  //   valueType: 0,
  //   fieldName: 'poppedJsonOutAcidSourWaterCorrosion01',
  //   default: undefined,
  //   initializer: undefined,
  //   type: 'output',
  // },
  // {
  //   keyword: 'poppedJsonOutAlkalineCarbonateSCC01',
  //   valueType: 0,
  //   fieldName: 'poppedJsonOutAlkalineCarbonateSCC01',
  //   default: undefined,
  //   initializer: undefined,
  //   type: 'output',
  // },
  // {
  //   keyword: 'poppedJsonOutAlkalineSourWaterCorrosion01',
  //   valueType: 0,
  //   fieldName: 'poppedJsonOutAlkalineSourWaterCorrosion01',
  //   default: undefined,
  //   initializer: undefined,
  //   type: 'output',
  // },
  // {
  //   keyword: 'poppedJsonOutBrittleFracture01',
  //   valueType: 0,
  //   fieldName: 'poppedJsonOutBrittleFracture01',
  //   default: undefined,
  //   initializer: undefined,
  //   type: 'output',
  // },
  // {
  //   keyword: 'poppedJsonOutCausticSCC01',
  //   valueType: 0,
  //   fieldName: 'poppedJsonOutCausticSCC01',
  //   default: undefined,
  //   initializer: undefined,
  //   type: 'output',
  // },
  // {
  //   keyword: 'poppedJsonOutChlorideSCC01',
  //   valueType: 0,
  //   fieldName: 'poppedJsonOutChlorideSCC01',
  //   default: undefined,
  //   initializer: undefined,
  //   type: 'output',
  // },
  // {
  //   keyword: 'poppedJsonOutCO2Corrosion01',
  //   valueType: 0,
  //   fieldName: 'poppedJsonOutCO2Corrosion01',
  //   default: undefined,
  //   initializer: undefined,
  //   type: 'output',
  // },
  // {
  //   keyword: 'poppedJsonOutCoolingWaterCorrosion01',
  //   valueType: 0,
  //   fieldName: 'poppedJsonOutCoolingWaterCorrosion01',
  //   default: undefined,
  //   initializer: undefined,
  //   type: 'output',
  // },
  // {
  //   keyword: 'poppedJsonOutHFAcidCorrosion01',
  //   valueType: 0,
  //   fieldName: 'poppedJsonOutHFAcidCorrosion01',
  //   default: undefined,
  //   initializer: undefined,
  //   type: 'output',
  // },
  // {
  //   keyword: 'poppedJsonOutHighTemperatureH2H2SCorrosion01',
  //   valueType: 0,
  //   fieldName: 'poppedJsonOutHighTemperatureH2H2SCorrosion01',
  //   default: undefined,
  //   initializer: undefined,
  //   type: 'output',
  // },
  // {
  //   keyword: 'poppedJsonOutHighTemperatureNapthenic01',
  //   valueType: 0,
  //   fieldName: 'poppedJsonOutHighTemperatureNapthenic01',
  //   default: undefined,
  //   initializer: undefined,
  //   type: 'output',
  // },
  // {
  //   keyword: 'poppedJsonOutHighTemperatureOxidationCorrosion01',
  //   valueType: 0,
  //   fieldName: 'poppedJsonOutHighTemperatureOxidationCorrosion01',
  //   default: undefined,
  //   initializer: undefined,
  //   type: 'output',
  // },
  // {
  //   keyword: 'poppedJsonOutHTHADamage01',
  //   valueType: 0,
  //   fieldName: 'poppedJsonOutHTHADamage01',
  //   default: undefined,
  //   initializer: undefined,
  //   type: 'output',
  // },
  // {
  //   keyword: 'poppedJsonOutHydrochloricAcidCorrosion01',
  //   valueType: 0,
  //   fieldName: 'poppedJsonOutHydrochloricAcidCorrosion01',
  //   default: undefined,
  //   initializer: undefined,
  //   type: 'output',
  // },
  // {
  //   keyword: 'poppedJsonOutHydrogenInducedCracking01',
  //   valueType: 0,
  //   fieldName: 'poppedJsonOutHydrogenInducedCracking01',
  //   default: undefined,
  //   initializer: undefined,
  //   type: 'output',
  // },
  // {
  //   keyword: 'poppedJsonOutHydrogenSCCInHF01',
  //   valueType: 0,
  //   fieldName: 'poppedJsonOutHydrogenSCCInHF01',
  //   default: undefined,
  //   initializer: undefined,
  //   type: 'output',
  // },
  // {
  //   keyword: 'poppedJsonOutLowAlloyEmbrittlement01',
  //   valueType: 0,
  //   fieldName: 'poppedJsonOutLowAlloyEmbrittlement01',
  //   default: undefined,
  //   initializer: undefined,
  //   type: 'output',
  // },
  // {
  //   keyword: 'poppedJsonOutOtherCracking01',
  //   valueType: 0,
  //   fieldName: 'poppedJsonOutOtherCracking01',
  //   default: undefined,
  //   initializer: undefined,
  //   type: 'output',
  // },
  // {
  //   keyword: 'poppedJsonOutOtherCorrosion01',
  //   valueType: 0,
  //   fieldName: 'poppedJsonOutOtherCorrosion01',
  //   default: undefined,
  //   initializer: undefined,
  //   type: 'output',
  // },
  // {
  //   keyword: 'poppedJsonOutMechanicalFatigue01',
  //   valueType: 0,
  //   fieldName: 'poppedJsonOutMechanicalFatigue01',
  //   default: undefined,
  //   initializer: undefined,
  //   type: 'output',
  // },
  // {
  //   keyword: 'poppedJsonOutPolyAcidSCC01',
  //   valueType: 0,
  //   fieldName: 'poppedJsonOutPolyAcidSCC01',
  //   default: undefined,
  //   initializer: undefined,
  //   type: 'output',
  // },
  // {
  //   keyword: 'poppedJsonOutSigmaPhaseEmbrittlement01',
  //   valueType: 0,
  //   fieldName: 'poppedJsonOutSigmaPhaseEmbrittlement01',
  //   default: undefined,
  //   initializer: undefined,
  //   type: 'output',
  // },
  // {
  //   keyword: 'poppedJsonOutSoilSideCorrosion01',
  //   valueType: 0,
  //   fieldName: 'poppedJsonOutSoilSideCorrosion01',
  //   default: undefined,
  //   initializer: undefined,
  //   type: 'output',
  // },
  // {
  //   keyword: 'poppedJsonOutSulfideSCC01',
  //   valueType: 0,
  //   fieldName: 'poppedJsonOutSulfideSCC01',
  //   default: undefined,
  //   initializer: undefined,
  //   type: 'output',
  // },
  // {
  //   keyword: 'poppedJsonOutSulfuricAcidCorrosion01',
  //   valueType: 0,
  //   fieldName: 'poppedJsonOutSulfuricAcidCorrosion01',
  //   default: undefined,
  //   initializer: undefined,
  //   type: 'output',
  // },
  // {
  //   keyword: 'poppedJsonOutTankBottomCorrosion01',
  //   valueType: 0,
  //   fieldName: 'poppedJsonOutTankBottomCorrosion01',
  //   default: undefined,
  //   initializer: undefined,
  //   type: 'output',
  // },
]
