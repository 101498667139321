/* eslint-disable react/jsx-indent */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import E2GSelectField, { E2GSelectFieldOption } from '@e2grnd/ui-core/dist/containers/E2GSelectField'
import Submit from '@e2grnd/ui-core/dist/containers/Submit'

function Step1({ style, problemType, activeUnitSystem, whichNetwork2a, whichNetwork2b, whichNetwork21, whichNetwork1, whichNetwork2, whichSubNetwork2a, whichSubNetwork2b, whichSubNetwork2c, whichSubNetwork2d, whichSubNetwork2e, whichSubNetwork2f, whichSubNetwork2g, whichSubNetwork2h, chlorideSource, amineType1, amineType0, mocAmineCorrosion, coolingWaterP2, mocHFAcidCorrosion, mocHighTempH2H2SCorrosion, mocHighTempNapthenicCorrosion, mocHydrochloricAcidCorrosion, mocSulfuricAcidCorrosion, mocSufidation }) {
  return (
    <div className="cf w-100">
      {problemType === 'PROB1'
        ? (
          <div>
            <E2GSelectField name="whichNetwork1" value={whichNetwork1} label="Damage Morphology">
              <E2GSelectFieldOption label="Cracking" value="Cracking" />
              <E2GSelectFieldOption label="Thinning" value="Thinning" />
            </E2GSelectField>
            {whichNetwork1 === 'Cracking'
              ? (
                <E2GSelectField name="chlorideSource" value={chlorideSource} label="Damage Source">
                  <E2GSelectFieldOption label="Internal" value="Internal" />
                  <E2GSelectFieldOption label="External" value="External" />
                </E2GSelectField>
              )
              : ''}
          </div>
        )
        : ''}
      {problemType === 'PROB2'
        ? (
          <div>
            <E2GSelectField name="whichNetwork21" value={whichNetwork21} label="Damage Morphology">
              <E2GSelectFieldOption label="Unknown" value="unknown" />
              <E2GSelectFieldOption label="Cracking" value="Cracking" />
              <E2GSelectFieldOption label="Thinning" value="Thinning" />
            </E2GSelectField>
            {whichNetwork21 === 'Cracking'
              ? (
                <div>
                  <E2GSelectField name="whichNetwork2a" value={whichNetwork2a} label="Damage Mechanism">
                    <E2GSelectFieldOption label="885 Embrittlement" value="885 Embrittlement" />
                    <E2GSelectFieldOption label="Alkaline Carbonate SCC" value="Alkaline Carbonate SCC" />
                    <E2GSelectFieldOption label="Amine Cracking" value="Amine Cracking" />
                    <E2GSelectFieldOption label="Brittle Fracture" value="Brittle Fracture" />
                    <E2GSelectFieldOption label="Caustic SCC" value="Caustic SCC" />
                    <E2GSelectFieldOption label="Chloride SCC" value="Chloride SCC" />
                    <E2GSelectFieldOption label="HTHA Damage" value="HTHA Damage" />
                    <E2GSelectFieldOption label="Hydrogen Induced Cracking in H2S" value="Hydrogen Induced Cracking in H2S" />
                    <E2GSelectFieldOption label="Hydrogen Induced Cracking in HF" value="Hydrogen Induced Cracking in HF" />
                    <E2GSelectFieldOption label="Hydrogen SCC in HF" value="Hydrogen SCC in HF" />
                    <E2GSelectFieldOption label="Low Alloy Embrittlement" value="Low Alloy Embrittlement" />
                    <E2GSelectFieldOption label="Mechanical Fatigue" value="Mechanical Fatigue" />
                    <E2GSelectFieldOption label="Other Cracking" value="Other Cracking" />
                    <E2GSelectFieldOption label="Poly Acid SCC" value="Poly Acid SCC" />
                    <E2GSelectFieldOption label="Sigma Phase Embrittlement" value="Sigma Phase Embrittlement" />
                    <E2GSelectFieldOption label="Sulfide SCC" value="Sulfide SCC" />
                  </E2GSelectField>
                </div>
              )
              : ''}
            {whichNetwork21 === 'Thinning'
              ? (
                <div>
                  <E2GSelectField name="whichNetwork2b" value={whichNetwork2b} label="Damage Mechanism">
                    <E2GSelectFieldOption label="Acid Sour Water Corrosion" value="Acid Sour Water Corrosion" />
                    <E2GSelectFieldOption label="Alkaline Sour Water Corrosion" value="Alkaline Sour Water Corrosion" />
                    <E2GSelectFieldOption label="Amine Corrosion" value="Amine Corrosion" />
                    <E2GSelectFieldOption label="CO2 Corrosion" value="CO2 Corrosion" />
                    <E2GSelectFieldOption label="Cooling Water Corrosion" value="Cooling Water Corrosion" />
                    <E2GSelectFieldOption label="HF Acid Corrosion" value="HF Acid Corrosion" />
                    <E2GSelectFieldOption label="High Temperature H2-H2S Corrosion" value="High Temperature H2-H2S Corrosion" />
                    <E2GSelectFieldOption label="High Temperature Napthenic" value="High Temperature Napthenic" />
                    <E2GSelectFieldOption label="High Temperature Oxidation Corrosion" value="High Temperature Oxidation Corrosion" />
                    <E2GSelectFieldOption label="Hydrochloric Acid Corrosion" value="Hydrochloric Acid Corrosion" />
                    <E2GSelectFieldOption label="Other Corrosion" value="Other Corrosion" />
                    <E2GSelectFieldOption label="Soil Side Corrosion" value="Soil Side Corrosion" />
                    <E2GSelectFieldOption label="Sulfidation" value="Sulfidation" />
                    <E2GSelectFieldOption label="Sulfuric Acid Corrosion" value="Sulfuric Acid Corrosion" />
                    <E2GSelectFieldOption label="Tank Bottom Corrosion" value="Tank Bottom Corrosion" />
                  </E2GSelectField>
                </div>
              )
              : ''}
            {whichNetwork21 === 'unknown'
              ? (
                <div>
                  <E2GSelectField name="whichNetwork2" value={whichNetwork2} label="Damage Mechanism">
                    <E2GSelectFieldOption label="885 Embrittlement" value="885 Embrittlement" />
                    <E2GSelectFieldOption label="Acid Sour Water Corrosion" value="Acid Sour Water Corrosion" />
                    <E2GSelectFieldOption label="Alkaline Carbonate SCC" value="Alkaline Carbonate SCC" />
                    <E2GSelectFieldOption label="Alkaline Sour Water Corrosion" value="Alkaline Sour Water Corrosion" />
                    <E2GSelectFieldOption label="Amine Corrosion" value="Amine Corrosion" />
                    <E2GSelectFieldOption label="Amine Cracking" value="Amine Cracking" />
                    <E2GSelectFieldOption label="Brittle Fracture" value="Brittle Fracture" />
                    <E2GSelectFieldOption label="Caustic SCC" value="Caustic SCC" />
                    <E2GSelectFieldOption label="Chloride SCC" value="Chloride SCC" />
                    <E2GSelectFieldOption label="CO2 Corrosion" value="CO2 Corrosion" />
                    <E2GSelectFieldOption label="Cooling Water Corrosion" value="Cooling Water Corrosion" />
                    <E2GSelectFieldOption label="HF Acid Corrosion" value="HF Acid Corrosion" />
                    <E2GSelectFieldOption label="High Temperature H2-H2S Corrosion" value="High Temperature H2-H2S Corrosion" />
                    <E2GSelectFieldOption label="High Temperature Napthenic" value="High Temperature Napthenic" />
                    <E2GSelectFieldOption label="High Temperature Oxidation Corrosion" value="High Temperature Oxidation Corrosion" />
                    <E2GSelectFieldOption label="HTHA Damage" value="HTHA Damage" />
                    <E2GSelectFieldOption label="Hydrochloric Acid Corrosion" value="Hydrochloric Acid Corrosion" />
                    <E2GSelectFieldOption label="Hydrogen Induced Cracking in H2S" value="Hydrogen Induced Cracking in H2S" />
                    <E2GSelectFieldOption label="Hydrogen Induced Cracking in HF" value="Hydrogen Induced Cracking in HF" />
                    <E2GSelectFieldOption label="Hydrogen SCC in HF" value="Hydrogen SCC in HF" />
                    <E2GSelectFieldOption label="Low Alloy Embrittlement" value="Low Alloy Embrittlement" />
                    <E2GSelectFieldOption label="Mechanical Fatigue" value="Mechanical Fatigue" />
                    <E2GSelectFieldOption label="Other Corrosion" value="Other Corrosion" />
                    <E2GSelectFieldOption label="Other Cracking" value="Other Cracking" />
                    <E2GSelectFieldOption label="Poly Acid SCC" value="Poly Acid SCC" />
                    <E2GSelectFieldOption label="Sigma Phase Embrittlement" value="Sigma Phase Embrittlement" />
                    <E2GSelectFieldOption label="Soil Side Corrosion" value="Soil Side Corrosion" />
                    <E2GSelectFieldOption label="Sulfidation" value="Sulfidation" />
                    <E2GSelectFieldOption label="Sulfide SCC" value="Sulfide SCC" />
                    <E2GSelectFieldOption label="Sulfuric Acid Corrosion" value="Sulfuric Acid Corrosion" />
                    <E2GSelectFieldOption label="Tank Bottom Corrosion" value="Tank Bottom Corrosion" />
                  </E2GSelectField>
                </div>
              )
              : ''}
            {(whichNetwork2b === 'Amine Corrosion' && whichNetwork21 === 'Thinning') || (whichNetwork2 === 'Amine Corrosion' && whichNetwork21 === 'unknown')
              ? (
                <div>
                  <E2GSelectField name="mocAmineCorrosion" value={mocAmineCorrosion} label="Material of Construction">
                    <E2GSelectFieldOption label="Carbon Steel" value="cs" />
                    <E2GSelectFieldOption label="Stainless Steel" value="ss" />
                  </E2GSelectField>
                  {mocAmineCorrosion === 'cs'
                    ? (
                      <div>
                        <E2GSelectField name="amineType1" value={amineType1} label="Amine Loading">
                          <E2GSelectFieldOption label="MDEA" value="MEA" />
                          <E2GSelectFieldOption label="MEA-DEA" value="DEA" />
                        </E2GSelectField>
                        <E2GSelectField name="amineType0" value={amineType0} label="Amine Strength">
                          <E2GSelectFieldOption label="Lean" value="Lean" />
                          <E2GSelectFieldOption label="Rich" value="Rich" />
                        </E2GSelectField>
                      </div>
                    )
                    : ''}
                </div>
              )
              : ''}
            {(whichNetwork2a === 'Chloride SCC' && whichNetwork21 === 'Cracking') || (whichNetwork2 === 'Chloride SCC' && whichNetwork21 === 'unknown')
              ? (
                <div>
                  <E2GSelectField name="chlorideSource" value={chlorideSource} label="Damage Source">
                    <E2GSelectFieldOption label="Internal" value="Internal" />
                    <E2GSelectFieldOption label="External" value="External" />
                  </E2GSelectField>
                </div>
              )
              : ''}
            {(whichNetwork2b === 'Cooling Water Corrosion' && whichNetwork21 === 'Thinning') || (whichNetwork2 === 'Cooling Water Corrosion' && whichNetwork21 === 'unknown')
              ? (
                <div>
                  <E2GSelectField name="coolingWaterP2" value={coolingWaterP2} label="Cooling Water">
                    <E2GSelectFieldOption label="Recirculating" value="Recirculating" />
                    <E2GSelectFieldOption label="Seawater Once Through" value="Seawater Once Through" />
                  </E2GSelectField>
                </div>
              )
              : ''}
            {(whichNetwork2b === 'HF Acid Corrosion' && whichNetwork21 === 'Thinning') || (whichNetwork2 === 'HF Acid Corrosion' && whichNetwork21 === 'unknown')
              ? (
                <div>
                  <E2GSelectField name="mocHFAcidCorrosion" value={mocHFAcidCorrosion} label="Material of Construction">
                    <E2GSelectFieldOption label="Carbon Steel" value="cs" />
                    <E2GSelectFieldOption label="Alloy 400" value="Alloy 400" />
                  </E2GSelectField>
                </div>
              )
              : ''}
            {(whichNetwork2b === 'High Temperature H2-H2S Corrosion' && whichNetwork21 === 'Thinning') || (whichNetwork2 === 'High Temperature H2-H2S Corrosion' && whichNetwork21 === 'unknown')
              ? (
                <div>
                  <E2GSelectField name="mocHighTempH2H2SCorrosion" value={mocHighTempH2H2SCorrosion} label="Material of Construction">
                    <E2GSelectFieldOption label="5Cr" value="5Cr" />
                    <E2GSelectFieldOption label="7Cr" value="7Cr" />
                    <E2GSelectFieldOption label="9Cr" value="9Cr" />
                    <E2GSelectFieldOption label="12Cr" value="12Cr" />
                    <E2GSelectFieldOption label="Low Alloy" value="Low Alloy" />
                    <E2GSelectFieldOption label="SS" value="SS" />
                  </E2GSelectField>
                </div>
              )
              : ''}
            {(whichNetwork2b === 'High Temperature Napthenic' && whichNetwork21 === 'Thinning') || (whichNetwork2 === 'High Temperature Napthenic' && whichNetwork21 === 'unknown')
              ? (
                <div>
                  <E2GSelectField name="mocHighTempNapthenicCorrosion" value={mocHighTempNapthenicCorrosion} label="Material of Construction">
                    <E2GSelectFieldOption label="5Cr" value="5Cr" />
                    <E2GSelectFieldOption label="7Cr" value="7Cr" />
                    <E2GSelectFieldOption label="9Cr" value="9Cr" />
                    <E2GSelectFieldOption label="12Cr" value="12Cr" />
                    <E2GSelectFieldOption label="316SS gt 2.5p Mo and 317SS" value="316SS gt 2.5p Mo and 317SS" />
                    <E2GSelectFieldOption label="316SS lt 2.5p Mo" value="316SS lt 2.5p Mo" />
                    <E2GSelectFieldOption label="CS" value="CS" />
                    <E2GSelectFieldOption label="Low Alloy" value="Low Alloy" />
                    <E2GSelectFieldOption label="SS no Mo" value="SS no Mo" />
                  </E2GSelectField>
                </div>
              )
              : ''}
            {(whichNetwork2b === 'Hydrochloric Acid Corrosion' && whichNetwork21 === 'Thinning') || (whichNetwork2 === 'Hydrochloric Acid Corrosion' && whichNetwork21 === 'unknown')
              ? (
                <div>
                  <E2GSelectField name="mocHydrochloricAcidCorrosion" value={mocHydrochloricAcidCorrosion} label="Material of Construction">
                    <E2GSelectFieldOption label="B-2 or 400" value="B2_400" />
                    <E2GSelectFieldOption label="Carbon Steel or Stainless Steel" value="CS_SS" />
                    <E2GSelectFieldOption label="Ni Alloys" value="Ni Alloys" />
                  </E2GSelectField>
                </div>
              )
              : ''}
            {(whichNetwork2b === 'Sulfuric Acid Corrosion' && whichNetwork21 === 'Thinning') || (whichNetwork2 === 'Sulfuric Acid Corrosion' && whichNetwork21 === 'unknown')
              ? (
                <div>
                  <E2GSelectField name="mocSulfuricAcidCorrosion" value={mocSulfuricAcidCorrosion} label="Material of Construction">
                    <E2GSelectFieldOption label="304SS" value="304SS" />
                    <E2GSelectFieldOption label="316SS" value="316SS" />
                    <E2GSelectFieldOption label="Alloy 20" value="Alloy 20" />
                    <E2GSelectFieldOption label="Alloy B2" value="Alloy B2" />
                    <E2GSelectFieldOption label="Alloy C276" value="Alloy C276" />
                    <E2GSelectFieldOption label="CS" value="CS" />
                  </E2GSelectField>
                </div>
              )
              : ''}
            {(whichNetwork2b === 'Sulfidation' && whichNetwork21 === 'Thinning') || (whichNetwork2 === 'Sulfidation' && whichNetwork21 === 'unknown')
              ? (
                <div>
                  <E2GSelectField name="mocSufidation" value={mocSufidation} label="Material of Construction">
                    <E2GSelectFieldOption label="CS" value="CS" />
                    <E2GSelectFieldOption label="1-3Cr" value="1-3Cr" />
                    <E2GSelectFieldOption label="4-6Cr" value="4-6Cr" />
                    <E2GSelectFieldOption label="7Cr" value="7Cr" />
                    <E2GSelectFieldOption label="9Cr" value="9Cr" />
                    <E2GSelectFieldOption label="12Cr" value="12Cr" />
                    <E2GSelectFieldOption label="SS" value="SS" />
                  </E2GSelectField>
                </div>
              )
              : ''}
          </div>
        )
        : ''}
      <div className="fl w-100">
        <Submit label="Next" />
      </div>
    </div>
  )
}
Step1.propTypes = {
  style: PropTypes.object,
  activeUnitSystem: PropTypes.string,
  problemType: PropTypes.string,
  whichNetwork1: PropTypes.string,
  whichNetwork2: PropTypes.string,
  whichNetwork2a: PropTypes.string,
  whichNetwork2b: PropTypes.string,
  whichNetwork21: PropTypes.string,
  whichSubNetwork2a: PropTypes.string,
  whichSubNetwork2b: PropTypes.string,
  whichSubNetwork2c: PropTypes.string,
  whichSubNetwork2d: PropTypes.string,
  whichSubNetwork2e: PropTypes.string,
  whichSubNetwork2f: PropTypes.string,
  whichSubNetwork2g: PropTypes.string,
  whichSubNetwork2h: PropTypes.string,
  chlorideSource: PropTypes.string,
  amineType1: PropTypes.string,
  amineType0: PropTypes.string,
  mocAmineCorrosion: PropTypes.string,
  coolingWaterP2: PropTypes.string,
  mocHFAcidCorrosion: PropTypes.string,
  mocHighTempH2H2SCorrosion: PropTypes.string,
  mocHighTempNapthenicCorrosion: PropTypes.string,
  mocHydrochloricAcidCorrosion: PropTypes.string,
  mocSulfuricAcidCorrosion: PropTypes.string,
  mocSufidation: PropTypes.string,
}

const mapStateToProps = state => ({
  style: state.ui.style,
  activeUnitSystem: state.workflow.activeUnitSystem,
  problemType: state.workflow.fields.problemType,
  whichNetwork1: state.workflow.fields.whichNetwork1,
  whichNetwork2: state.workflow.fields.whichNetwork2,
  whichNetwork21: state.workflow.fields.whichNetwork21,
  whichNetwork2a: state.workflow.fields.whichNetwork2a,
  whichNetwork2b: state.workflow.fields.whichNetwork2b,
  whichSubNetwork2a: state.workflow.fields.whichSubNetwork2a,
  whichSubNetwork2b: state.workflow.fields.whichSubNetwork2b,
  whichSubNetwork2c: state.workflow.fields.whichSubNetwork2c,
  whichSubNetwork2d: state.workflow.fields.whichSubNetwork2d,
  whichSubNetwork2e: state.workflow.fields.whichSubNetwork2e,
  whichSubNetwork2f: state.workflow.fields.whichSubNetwork2f,
  whichSubNetwork2g: state.workflow.fields.whichSubNetwork2g,
  whichSubNetwork2h: state.workflow.fields.whichSubNetwork2h,
  chlorideSource: state.workflow.fields.chlorideSource,
  amineType1: state.workflow.fields.amineType1,
  amineType0: state.workflow.fields.amineType0,
  mocAmineCorrosion: state.workflow.fields.mocAmineCorrosion,
  coolingWaterP2: state.workflow.fields.coolingWaterP2,
  mocHFAcidCorrosion: state.workflow.fields.mocHFAcidCorrosion,
  mocHighTempH2H2SCorrosion: state.workflow.fields.mocHighTempH2H2SCorrosion,
  mocHighTempNapthenicCorrosion: state.workflow.fields.mocHighTempNapthenicCorrosion,
  mocHydrochloricAcidCorrosion: state.workflow.fields.mocHydrochloricAcidCorrosion,
  mocSulfuricAcidCorrosion: state.workflow.fields.mocSulfuricAcidCorrosion,
  mocSufidation: state.workflow.fields.mocSufidation,
})

export default connect(mapStateToProps)(Step1)
